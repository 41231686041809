/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import CardNoMatches from '~components/general/CardNoMatches'
import Auction from '~components/general/Auction'
import DropdownFilter from '~components/ui/DropdownFilter'
import DropdownFilterContext from '~contexts/DropdownFilterContext'
import { MOCK_PROFILE_PAGE_AUCTIONS } from '~utils/mocks'
import { LiveAuctionsSortType } from '~utils/enums'
import * as style from './LiveAuctions.module.scss'

/**
 * LiveAuctionsPage component
 */
const LiveAuctionsPage = (props) => {
  const { data } = props
  const { site } = data

  const [filters, setFilters] = useState({
    sortType: LiveAuctionsSortType.RECENTLY_ADDED,
  })

  const followingAuctionsFilter = () => true

  const filteredAuctions = MOCK_PROFILE_PAGE_AUCTIONS.filter(
    followingAuctionsFilter
  )

  const sortAuctions = (auctionA, auctionB) => {
    if (filters.sortType === LiveAuctionsSortType.LOWEST_PRICE_FIRST)
      return auctionA.price - auctionB.price

    if (filters.sortType === LiveAuctionsSortType.HIGHEST_PRICE_FIRST)
      return auctionB.price - auctionA.price

    if (filters.sortType === LiveAuctionsSortType.RECENTLY_ADDED)
      return auctionB.timestampCreated - auctionA.timestampCreated

    return 0
  }

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.FOLLOWING}
    >
      <Container>
        <S title="Live Auctions" />
        <h2 className={style.pageHeading}>Live Auctions</h2>
        <div className={style.gridNav}>
          <DropdownFilterContext.Provider value={{ filters, setFilters }}>
            <DropdownFilter
              btnText="Recently Added"
              filterEnum={LiveAuctionsSortType}
              filterKey="sortType"
              iconName="type"
              isClearable={false}
            />
          </DropdownFilterContext.Provider>
        </div>
        {filteredAuctions.length ? (
          <div className={style.gridAuctions}>
            {filteredAuctions
              .sort(sortAuctions)
              .map(({ id, category, status, ...auctionProps }) => (
                <Auction key={id} {...auctionProps} />
              ))}
          </div>
        ) : (
          <CardNoMatches />
        )}
      </Container>
    </Layout>
  )
}

export default LiveAuctionsPage
