import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { Link } from 'gatsby'

import * as style from './ItemCommunityUpvote.module.scss'
import TEXT_BTN from './constants'

/**
 * ItemCommunityUpvote component
 */
const ItemCommunityUpvote = (props) => {
  const {
    className,
    place,
    name,
    username,
    profileUrl,
    numberOfVotes,
    hasReceivedVote,
    img,
    ...rest
  } = props

  return (
    <div {...rest} className={classNames(style.itemCommunityUpvote, className)}>
      <div className={style.textPlace}>{place}</div>
      <Link to={profileUrl}>
        <img
          src={img}
          width={56}
          height={56}
          alt={name}
          className={style.img}
        />
      </Link>
      <div>
        <Link to={profileUrl} className={style.textName}>
          {name}
        </Link>
        <p className={style.textUsername}>@{username}</p>
      </div>
      <div className={style.blockVote}>
        <p className={style.textVotesWrapper}>
          <span>
            <span
              className={classNames(style.textVotesNumber, {
                [style.textVotesVoted]: hasReceivedVote,
              })}
            >
              {numberOfVotes}
            </span>{' '}
            <span className={style.textVotesRegular}>votes</span>
          </span>
        </p>
        <Button
          type="button"
          variant="secondary"
          disabled={hasReceivedVote}
          className={style.btnVote}
        >
          {TEXT_BTN[hasReceivedVote ? 'upvoted' : 'upvote']}
        </Button>
      </div>
    </div>
  )
}

ItemCommunityUpvote.defaultProps = {
  className: '',
  hasReceivedVote: false,
}

ItemCommunityUpvote.propTypes = {
  className: PropTypes.string,
  place: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  numberOfVotes: PropTypes.number.isRequired,
  hasReceivedVote: PropTypes.bool,
}

export default ItemCommunityUpvote
