import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './CreateNtfModal.module.scss'
import InputWLabel from '~components/ui/InputWLabel'
import InputImage from '~components/ui/InputImage'

/**
 * CreateNtfModal component
 */
const CreateNtfModal = (props) => {
  const { show, onHide, className, ...rest } = props

  const methods = useForm()

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods

  const onSubmit = () => {
    onHide()
    reset()
  }

  return (
    <Modal
      {...rest}
      show={show}
      onHide={onHide}
      scrollable
      contentClassName={className}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create an Image NFT</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Modal.Body
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          id="form-verify-community-upvote"
        >
          <div className={style.blockInner}>
            <InputImage
              label="NFT artwork"
              id="inp-nft-artwork"
              name="imgArtwork"
              text="Drag & drop an image here, or click to browse"
              hint="3000x3000px. JPG or PNG. 50MB max size."
              dropzoneOptions={{
                accept: 'image/jpeg, image/png',
                maxSize: 50 * 1000 * 1000, // 5MB
                maxFiles: 1,
                multiple: false,
              }}
              msgFileType="Please, submit a jpg or png image"
              required
              errors={errors}
            />
            <InputWLabel
              id="inp-nft-name"
              label="Name"
              placeholder="Enter an NFT name"
              errors={errors}
              {...register('nft-name', {
                required: 'Please, specify an NFT name',
              })}
            />
            <InputWLabel
              id="inp-description"
              label="Description"
              placeholder="Enter an NFT description"
              errors={errors}
              as="textarea"
              variant="textarea"
              rows="8"
              maxlength="280"
              classNameFormControl={style.inpTextArea}
              {...register('nft-description', {
                required: 'Please, specify an NFT description',
              })}
            />
          </div>
        </Modal.Body>
      </FormProvider>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          form="form-verify-community-upvote"
        >
          Verify
        </Button>
      </Modal.Footer>
      <DevTool control={control} />
    </Modal>
  )
}

CreateNtfModal.defaultProps = {
  className: '',
}

CreateNtfModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default CreateNtfModal
