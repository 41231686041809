import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card } from 'react-bootstrap'

import * as style from './CardNoMatches.module.scss'

/**
 * CardNoMatches component
 */
const CardNoMatches = (props) => {
  const { className, ...rest } = props

  return (
    <Card {...rest} className={classNames(style.cardNoMatches, className)}>
      <h4>We couldn&apos;t find any matches</h4>
      <p>Please double check your spelling or try a different search term</p>
    </Card>
  )
}

CardNoMatches.defaultProps = {
  className: '',
}

CardNoMatches.propTypes = {
  className: PropTypes.string,
}

export default CardNoMatches
