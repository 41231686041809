// extracted by mini-css-extract-plugin
export var sectionHeading = "D_X";
export var topSellerSection = "D_ch";
export var sm = "D_cj";
export var mdLg = "D_ck";
export var blendOverlay = "D_cl";
export var scrollContainer = "D_cm";
export var topSellerDeck = "D_cn";
export var md = "D_cp";
export var lg = "D_cq";
export var rotate = "D_c";
export var dotty = "D_d";