/* eslint-disable react/prop-types */

import React, { useState } from 'react'

import { Container } from 'react-bootstrap'
import * as style from './NotificationsPage.module.scss'
import { NotificationCategory } from '~utils/enums'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import { MOCK_NOTIFICATIONS } from '~utils/mocks'
import NotificationItem from '../../components/general/NotificationItem'
import NotificationFilters from './components/NotificationFilters'
import Route from '~routes'

/**
 * Notifications component
 */
const NotificationsPage = (props) => {
  const { data } = props
  const { site } = data

  const [notificationsFilter, setNotificationsFilter] = useState(
    Object.values(NotificationCategory)
  )

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.NOTIFICATIONS}
    >
      <Container>
        <S title="Notifications" />
        <h2 className={style.pageHeading}>Notifications</h2>
        <div className={style.gridMain}>
          <ul className={style.gridNotifications}>
            {MOCK_NOTIFICATIONS.filter(
              (notification) =>
                !notificationsFilter.length ||
                notificationsFilter.includes(notification.category)
            ).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                variant="page"
              />
            ))}
          </ul>
          <NotificationFilters
            notificationsFilter={notificationsFilter}
            setNotificationsFilter={setNotificationsFilter}
            className={style.notificationFilters}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default NotificationsPage
