const ExploreUserType = {
  sellers: 'Sellers',
  buyers: 'Buyers',
}

const ExploreDateRange = {
  day: '1 day',
  week: 'Week',
  month: 'Month',
}

export { ExploreUserType, ExploreDateRange }
