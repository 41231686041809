import React, { useState } from 'react'

import * as style from './BlockCreateNft.module.scss'
import CreateNtfModal from '../CreateNtfModal'
import CreateOption from '../CreateOption'

/**
 * BlockCreateNft component
 */
const BlockCreateNft = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <CreateNtfModal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      />
      <div className={style.gridCreateOptions}>
        <CreateOption
          heading="Image"
          format="JPG or PNG"
          icon="image"
          onClick={() => setIsModalVisible(true)}
        />
        <CreateOption heading="Video" format="MP4" icon="video" />
      </div>
    </>
  )
}

export default BlockCreateNft
