import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'react-bootstrap'
import VerificationModal from '../VerificationModal'

/**
 * BlockVerification component
 */
const BlockVerification = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, setIsVerified, ...rest } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <VerificationModal
        show={isModalVisible}
        setIsVerified={setIsVerified}
        onHide={() => setIsModalVisible(false)}
      />
      <Button
        {...rest}
        variant="primary"
        className={className}
        onClick={() => setIsModalVisible(true)}
      >
        Verify to join
      </Button>
    </>
  )
}

BlockVerification.defaultProps = {
  className: '',
}

BlockVerification.propTypes = {
  className: PropTypes.string,
}

export default BlockVerification
