import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import dayjs from 'dayjs'
import { Link } from 'gatsby'

import * as style from './HeroCard.module.scss'
import BidCountdown from '~components/general/BidCountdown'
import Route from '~routes'

const endsAtTimestamp = dayjs().add(16, 'h').add(40, 'm').add(12, 's').unix()

/**
 * HeroCard component
 * TODO: extract data to props
 */
const HeroCard = (props) => {
  const {
    className,
    imgSrc,
    title,
    authorName,
    authorImgSrc,
    auctionId,
    ...rest
  } = props

  return (
    <div {...rest} className={classNames(style.heroCard, className)}>
      <div className={style.heroImageWrapper}>
        <div className={style.heroImageAspectRatioContainer}>
          <img src={imgSrc} alt={title} className={style.heroImage} />
        </div>
      </div>
      <div className={style.heroCardInfo}>
        <div className={style.authorBadge}>
          <div className={style.authorAvatar}>
            <img
              src={authorImgSrc}
              alt={authorName}
              width="100%"
              height="100%"
            />
          </div>
          <p className={style.authorName}>{authorName}</p>
        </div>
        <h1 className={style.heading}>{title}</h1>
        <BidCountdown
          className={style.blockCountdown}
          endsAtTimestamp={endsAtTimestamp}
          price={10.3905}
          priceUsd="2,163.2730"
          currencyName="johnsmith coins"
          variant="heroCard"
        />
        <div className={style.buttonRow}>
          <Button
            variant="primary"
            as={Link}
            to={`${Route.AUCTION}${auctionId}`}
          >
            Place a Bid
          </Button>
          <Button
            variant="secondary"
            as={Link}
            to={`${Route.AUCTION}${auctionId}`}
          >
            View Artwork
          </Button>
        </div>
      </div>
    </div>
  )
}

HeroCard.defaultProps = {
  className: '',
}

HeroCard.propTypes = {
  className: PropTypes.string,
  authorImgSrc: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  auctionId: PropTypes.any.isRequired,
}

export default HeroCard
