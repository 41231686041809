import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap'

import * as style from './ModalFollowers.module.scss'
import ItemFollower from '~components/general/ItemFollower'
import { MOCK_FOLLOWERS, MOCK_FOLLOWING } from '~utils/mocks'
import InputSearch from '~components/ui/InputSearch'
import { objectFieldMatchesSearch } from '~utils'

/**
 * ModalFollowers component
 */
const ModalFollowers = (props) => {
  const { className, activeTab, onHide, ...rest } = props

  const [followersFilter, setFollowersFilter] = useState('')
  const [followingFilter, setFollowingFilter] = useState('')

  const filterFollowers = (user, filterString) => {
    if (!filterString) return true
    return (
      objectFieldMatchesSearch(user.name, filterString) ||
      objectFieldMatchesSearch(user.username, filterString)
    )
  }

  return (
    <Modal
      {...rest}
      show={!!activeTab}
      onHide={onHide}
      scrollable
      contentClassName={className}
    >
      <TabContainer defaultActiveKey={activeTab}>
        <Modal.Header closeButton className={style.modalHeader}>
          <Nav variant="modalHeader" defaultActiveKey={activeTab}>
            <Nav.Item>
              <Nav.Link eventKey="followers" as="button">
                Followers<span className="nav-link-number">10</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="following" as="button">
                Following<span className="nav-link-number">120</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Modal.Header>
        <Modal.Body className={style.modalBody}>
          <TabContent>
            <TabPane eventKey="followers">
              <div className={style.tabPaneContent}>
                <InputSearch
                  placeholder="Search"
                  isSearchFilter
                  className={style.inpSearch}
                  value={followersFilter}
                  onChange={(e) => setFollowersFilter(e.target.value)}
                  onClear={() => setFollowersFilter('')}
                />
                <div className={style.gridFollowers}>
                  {MOCK_FOLLOWERS.filter((user) =>
                    filterFollowers(user, followersFilter)
                  ).map(({ id, ...followerProps }) => (
                    <ItemFollower key={id} {...followerProps} />
                  ))}
                </div>
              </div>
            </TabPane>
            <TabPane eventKey="following">
              <div className={style.tabPaneContent}>
                <InputSearch
                  placeholder="Search"
                  isSearchFilter
                  className={style.inpSearch}
                  value={followingFilter}
                  onChange={(e) => setFollowingFilter(e.target.value)}
                  onClear={() => setFollowingFilter('')}
                />
                <div className={style.gridFollowers}>
                  {MOCK_FOLLOWING.filter((user) =>
                    filterFollowers(user, followingFilter)
                  ).map(({ id, ...followingProps }) => (
                    <ItemFollower key={id} {...followingProps} />
                  ))}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Modal.Body>
      </TabContainer>
    </Modal>
  )
}

ModalFollowers.defaultProps = {
  className: '',
}

ModalFollowers.propTypes = {
  className: PropTypes.string,
  activeTab: PropTypes.any.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default ModalFollowers
