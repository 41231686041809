import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './VerificationModal.module.scss'
import InputCopy from '~components/ui/InputCopy'
import InputWLabel from '~components/ui/InputWLabel'
import { RHF_TWITTER_RULE } from '~utils/constants'

/**
 * VerificationModal component
 */
const VerificationModal = (props) => {
  // eslint-disable-next-line react/prop-types
  const { show, onHide, className, setIsVerified, ...rest } = props

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const onSubmit = () => {
    onHide()
    setIsVerified(true)
  }

  return (
    <Modal
      {...rest}
      show={show}
      onHide={onHide}
      scrollable
      contentClassName={className}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        id="form-verify-community-upvote"
      >
        <div className={style.blockInner}>
          <p className={style.textHeading}>
            Please, verify your profile to upload NFTs
          </p>
          <div className={style.blockWrapperInpCopy}>
            <Form.Label>
              Post a public tweet that contains your wallet address
            </Form.Label>
            <InputCopy value="0xA9139B325Aa3b91DDDd778Ec36dd47EAa3bdFAaa2E1" />
          </div>
          <InputWLabel
            id="inp-tweet-url"
            name="tweet-url"
            label="Paste the URL of the tweet to verify your profile"
            placeholder="Tweet URL"
            type="url"
            errors={errors}
            {...register('tweet-url', {
              required: 'Please, specify the tweet url',
              ...RHF_TWITTER_RULE,
            })}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          form="form-verify-community-upvote"
        >
          Verify
        </Button>
      </Modal.Footer>
      <DevTool control={control} />
    </Modal>
  )
}

VerificationModal.defaultProps = {
  className: '',
}

VerificationModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default VerificationModal
