import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap'
import * as style from './AuctionDetails.module.scss'
import MenuMoreActions from '~components/ui/MenuMoreActions'
import AuctionRelatedEntity from '../AuctionRelatedEntity'
import AuctionHistoryItem from '../AuctionHistoryItem'
import BidCountdown from '~components/general/BidCountdown'
import BidModal from '../BidModal'
import { AUCTION_MENU_ITEMS } from '~utils/constants'

/**
 * AuctionDetails component
 */
const AuctionDetails = (props) => {
  const {
    className,
    name,
    img,
    numberInStock,
    numberTotal,
    price,
    priceUsd,
    currencyName,
    percentOfSalesToCreator,
    description,
    owner,
    creator,
    collection,
    history,
    endsAtTimestamp,
    ...rest
  } = props

  // eslint-disable-next-line react/prop-types
  const CardCountdown = ({ className: extraClassName, onClickPlaceBid }) => (
    <div className={classNames(style.cardCountdownWrapper, extraClassName)}>
      <div className={style.cardCountdown}>
        <BidCountdown
          endsAtTimestamp={endsAtTimestamp}
          variant="card"
          price={price}
          priceUsd={priceUsd}
          currencyName={currencyName}
          className={style.bidCountdown}
        />
        <Button variant="primary" onClick={onClickPlaceBid}>
          Place a bid
        </Button>
      </div>
    </div>
  )

  const handleMenuSelect = () => {}

  const [isBidModalVisible, setIsBidModalVisible] = useState(false)

  return (
    <>
      <BidModal
        show={isBidModalVisible}
        onHide={() => setIsBidModalVisible(false)}
      />
      <div {...rest} className={classNames(style.auctionDetails, className)}>
        <div className={style.imgWrapper}>
          <div className={style.imgAspectRatioContainer}>
            <img src={img} alt={name} className={style.img} />
          </div>
        </div>
        <div className={style.blockDetails}>
          <div className={style.gridHead}>
            <div className={style.rowInStock}>
              <div className={style.badgeInStock}>
                {numberInStock}/{numberTotal} in stock
              </div>
              <MenuMoreActions
                menuItems={AUCTION_MENU_ITEMS}
                onSelect={handleMenuSelect}
                className={style.menuMoreActions}
              />
            </div>
            <h1 className={style.heading}>{name}</h1>
            <div className={style.rowFigures}>
              <div className={style.gridPrice}>
                <p className={style.textPriceCoins}>
                  {price} {currencyName}
                </p>
                <p className={style.textPriceUsd}>~ ${priceUsd}</p>
              </div>
              <p className={style.textPercentToCreator}>
                <span className={style.textWhite}>
                  {percentOfSalesToCreator}%
                </span>{' '}
                of sales will go to creator
              </p>
            </div>
          </div>
          <p className={style.description}>{description}</p>
          <div className={style.stickyContainer}>
            <TabContainer defaultActiveKey="details">
              <Nav
                variant="white"
                defaultActiveKey="details"
                className={style.nav}
              >
                <Nav.Item>
                  <Nav.Link eventKey="details">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history">History</Nav.Link>
                </Nav.Item>
              </Nav>
              <TabContent className={style.tabContainer}>
                <TabPane eventKey="details">
                  <div className={style.tabDetailsContent}>
                    <AuctionRelatedEntity
                      name={`@${owner.name}`}
                      description="Owner"
                      img={owner.img}
                      profileUrl={owner.profileUrl}
                    />
                    <AuctionRelatedEntity
                      name={`@${creator.name}`}
                      description="Creator"
                      img={creator.img}
                      profileUrl={creator.profileUrl}
                    />
                    <AuctionRelatedEntity
                      name={collection.city}
                      description="Collection"
                      img={collection.img}
                      profileUrl={collection.collectionUrl}
                    />
                  </div>
                </TabPane>
                <TabPane eventKey="history">
                  <div className={style.tabHistoryContent}>
                    {history.map((historyEntry) => (
                      <AuctionHistoryItem
                        key={historyEntry.timestamp}
                        {...historyEntry}
                      />
                    ))}
                  </div>
                </TabPane>
              </TabContent>
            </TabContainer>
            <CardCountdown onClickPlaceBid={() => setIsBidModalVisible(true)} />
          </div>
        </div>
        <CardCountdown
          className={style.cardCountdownLg}
          onClickPlaceBid={() => setIsBidModalVisible(true)}
        />
      </div>
    </>
  )
}

AuctionDetails.defaultProps = {
  className: '',
}

AuctionDetails.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  numberInStock: PropTypes.number.isRequired,
  numberTotal: PropTypes.number.isRequired,
  price: PropTypes.any.isRequired,
  priceUsd: PropTypes.any.isRequired,
  currencyName: PropTypes.string.isRequired,
  percentOfSalesToCreator: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string,
    profileUrl: PropTypes.string,
  }).isRequired,
  creator: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string,
    profileUrl: PropTypes.string,
  }).isRequired,
  collection: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string,
    img: PropTypes.string,
    collectionUrl: PropTypes.string,
  }).isRequired,
  endsAtTimestamp: PropTypes.number.isRequired,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      user: PropTypes.object,
      price: PropTypes.object,
      timestamp: PropTypes.number,
    })
  ).isRequired,
}

export default AuctionDetails
