import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import { Accordion } from 'react-bootstrap'

import * as style from './CardTransactions.module.scss'
import ItemTransaction from '../ItemTransaction'
import { INITIAL_DATE_RANGE_ARRAY } from '~utils/constants'
import { MOCK_TRANSACTIONS } from '~utils/mocks'
import BtnWIconToggle from '~components/ui/BtnWIconToggle'
import DropdownFilter from '~components/ui/DropdownFilter'
import { TransactionStatus, TransactionType } from '~utils/enums'
import TransactionFilterContext from '~contexts/DropdownFilterContext'
import DropdownDateRange from '../DropdownDateRange'
import InputSearch from '~components/ui/InputSearch'
import { objectFieldMatchesSearch } from '~utils'

dayjs.extend(isBetween)

/**
 * CardTransactions component
 */
const CardTransactions = (props) => {
  const { className, ...rest } = props

  const filterBarRef = useRef()

  const [transactionFilters, setTransactionFilters] = useState({
    date: INITIAL_DATE_RANGE_ARRAY,
    search: '',
  })

  const [isFilterBarExtended, setIsFilterBarExtended] = useState(false)

  const filterTransactions = (transaction) => {
    if (!isFilterBarExtended) return true

    /* Type match */
    const typeMatch = transactionFilters?.type
      ? transaction.type === transactionFilters?.type
      : true

    /* Status match */
    const statusMatch = transactionFilters?.status
      ? transaction.status === transactionFilters?.status
      : true

    /* Date match */
    let dateMatches = true

    const dateFilter = transactionFilters.date
    const { startDate, endDate } = dateFilter?.[0]

    if (startDate && endDate) {
      dateMatches = dayjs
        .unix(transaction.timestampCreated)
        .isBetween(startDate, endDate, 'day', '[]')
    }

    /* Search match (example, not final) */
    let searchQueryMatches = true

    const searchQuery = transactionFilters.search
    if (searchQuery) {
      searchQueryMatches =
        objectFieldMatchesSearch(
          transaction.amountMain?.currency,
          searchQuery
        ) ||
        objectFieldMatchesSearch(
          transaction.amountExtra?.currency,
          searchQuery
        ) ||
        objectFieldMatchesSearch(transaction.user?.name, searchQuery) ||
        objectFieldMatchesSearch(transaction.type, searchQuery) ||
        objectFieldMatchesSearch(transaction.status, searchQuery)
    }

    return typeMatch && statusMatch && dateMatches && searchQueryMatches
  }

  return (
    <div {...rest} className={classNames(style.cardTransactions, className)}>
      <Accordion>
        <div className={style.rowHeading}>
          <h5>Transactions</h5>
          <BtnWIconToggle
            eventKey="0"
            setIsFilterBarExtended={setIsFilterBarExtended}
          />
        </div>
        <Accordion.Collapse eventKey="0">
          <div className={style.filterBarContainer}>
            <TransactionFilterContext.Provider
              value={{
                filters: transactionFilters,
                setFilters: setTransactionFilters,
              }}
            >
              <div className={style.filterBar} ref={filterBarRef}>
                <DropdownFilter
                  filterEnum={TransactionType}
                  filterKey="type"
                  btnText="Transaction Type"
                />
                <DropdownFilter
                  filterEnum={TransactionStatus}
                  filterKey="status"
                  btnText="Status"
                />
                <DropdownDateRange btnText="Date" filterKey="date" />
              </div>
              <InputSearch
                className={style.inpSearch}
                variant="transactions"
                placeholder="Search"
                isSearchFilter
                onChange={(e) =>
                  setTransactionFilters((prevState) => ({
                    ...prevState,
                    search: e.target.value,
                  }))
                }
                onClear={() =>
                  setTransactionFilters((prevState) => ({
                    ...prevState,
                    search: '',
                  }))
                }
                value={transactionFilters.search}
              />
            </TransactionFilterContext.Provider>
          </div>
        </Accordion.Collapse>
      </Accordion>
      <div className={style.containerTransactions}>
        <div className={style.itemHeader}>
          <p>Transaction type</p>
          <p className={style.textStatus}>Status</p>
          <p className={style.textCreationDate}>Creation date</p>
          <p className={style.textAmount}>Amount</p>
        </div>
        {MOCK_TRANSACTIONS.filter(filterTransactions).map(
          ({ id, ...transactionProps }) => (
            <ItemTransaction
              key={id}
              id={id}
              className={style.cardTransaction}
              {...transactionProps}
            />
          )
        )}
      </div>
    </div>
  )
}

CardTransactions.defaultProps = {
  className: '',
}

CardTransactions.propTypes = {
  className: PropTypes.string,
}

export default CardTransactions
