import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './mintingBlocks.module.scss'
import Icon from '~components/ui/Icon'

/**
 * BlockConfirmation component
 */
const BlockConfirmation = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.columnInfo, className)}>
      <h2
        className={classNames(
          style.textHeading,
          style.marginLg,
          style.textLoadingDotty
        )}
      >
        Waiting for confirmation
      </h2>
      <p className={style.textDescription} style={{ marginTop: '16px' }}>
        Confirm this transaction with your wallet to continue.
      </p>
      <Icon
        name="icon-nft-mint-loading"
        size={60}
        className={style.iconLoading}
      />
    </div>
  )
}

BlockConfirmation.defaultProps = {
  className: '',
}

BlockConfirmation.propTypes = {
  className: PropTypes.string,
}

export default BlockConfirmation
