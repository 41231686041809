import clamp from 'lodash/clamp'
import { useEffect } from 'react'

const useVerticalToHorizontalScroll = (
  scrollContainerRef,
  maxScrollStep = 25
) => {
  const handleScroll = (e) => {
    e.preventDefault()
    // noinspection JSSuspiciousNameCombination
    e.currentTarget.scrollLeft +=
      e.deltaX || clamp(e.deltaY, -maxScrollStep, maxScrollStep)
  }

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef.current

    if (!scrollContainerEl) return

    scrollContainerEl.addEventListener('wheel', handleScroll)

    // eslint-disable-next-line consistent-return
    return () => scrollContainerEl.removeEventListener('wheel', handleScroll)
  }, [scrollContainerRef.current])
}

export default useVerticalToHorizontalScroll
