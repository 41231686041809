/* eslint-disable react/prop-types */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './LocationsFilterBar.module.scss'
import { MOCK_CITIES, MOCK_COUNTRIES } from '~utils/mocks'
import FilterWithImageItem from '../FilterWithImageItem'
import useVerticalToHorizontalScroll from '~hooks/useVerticalToHorizontalScroll'

/**
 * LocationsFilterBar component
 */
const LocationsFilterBar = (props) => {
  const {
    className,
    activeCityIds,
    activeCountry,
    setActiveCountry,
    setActiveCityIds,
    filterAuctions,
    ...rest
  } = props

  const countriesScrollRef = useRef()
  const citiesScrollRef = useRef()

  useVerticalToHorizontalScroll(countriesScrollRef)
  useVerticalToHorizontalScroll(citiesScrollRef)

  const searchMode = !activeCountry ? 'country' : 'city'

  return (
    <div {...rest} className={classNames(style.filtersBarWrapper, className)}>
      <div className={style.filtersBarScroll} ref={countriesScrollRef}>
        <div className={style.filtersBar}>
          {MOCK_COUNTRIES.filter((country) =>
            activeCountry?.id ? country.id === activeCountry?.id : true
          ).map((country) => (
            <FilterWithImageItem
              key={country.id}
              itemId={country.id}
              isActive={activeCountry?.id === country.id}
              onSelect={() => setActiveCountry(country)}
              onClear={() => {
                setActiveCountry(null)
                setActiveCityIds([])
              }}
              {...country}
            />
          ))}
        </div>
      </div>
      {searchMode === 'city' && (
        <div className={style.filtersBarScroll} ref={citiesScrollRef}>
          <div className={style.filtersBar}>
            {MOCK_CITIES.filter(
              (city) => city.countryId === activeCountry?.id
            ).map((city) => (
              <FilterWithImageItem
                key={city.id}
                itemId={city.id}
                isActive={activeCityIds.includes(city.id)}
                onSelect={() =>
                  setActiveCityIds((prevState) => [...prevState, city.id])
                }
                onClear={() =>
                  setActiveCityIds((prevState) =>
                    prevState.filter((cityId) => cityId !== city.id)
                  )
                }
                {...city}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

LocationsFilterBar.defaultProps = {
  className: '',
}

LocationsFilterBar.propTypes = {
  className: PropTypes.string,
}

export default LocationsFilterBar
