// extracted by mini-css-extract-plugin
export var modalBid = "bv_gP";
export var modalBody = "bv_fk";
export var textSup = "bv_x";
export var blockInner = "bv_gx";
export var gridDetails = "bv_gQ";
export var blockBottom = "bv_gR";
export var btnBid = "bv_gS";
export var textBottom = "bv_gT";
export var textBottomLink = "bv_gV";
export var rotate = "bv_c";
export var dotty = "bv_d";