// extracted by mini-css-extract-plugin
export var itemCommunityUpvote = "bz_g6";
export var textPlace = "bz_g7";
export var img = "bz_cz";
export var textName = "bz_gq";
export var textUsername = "bz_g8";
export var blockVote = "bz_g9";
export var textVotesWrapper = "bz_hb";
export var textVotesNumber = "bz_dR";
export var textVotesVoted = "bz_hc";
export var textVotesRegular = "bz_hd";
export var btnVote = "bz_hf";
export var rotate = "bz_c";
export var dotty = "bz_d";