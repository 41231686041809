import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import { Link } from 'gatsby'
import * as style from './CardPersonal.module.scss'
import Icon from '~components/ui/Icon'
import InputCopy from '~components/ui/InputCopy'
import ModalEditProfile from '../ModalEditProfile'
import MenuMoreActions from '~components/ui/MenuMoreActions'
import ModalFollowers from '~components/general/ModalFollowers'
import Route from '~routes'

/**
 * CardPersonal component
 */

const CardPersonal = (props) => {
  const {
    className,
    isOwnProfile,
    name,
    username,
    bannerImg,
    avatarImg,
    followers,
    following,
    walletAddress,
    isFollowed,
    variant,
    ...rest
  } = props

  const [isModalEditProfileVisible, setIsModalEditProfileVisible] =
    useState(false)
  const [modalFollowersActiveTab, setModalFollowersActiveTab] = useState(false)

  const handleClose = () => {
    setIsModalEditProfileVisible(false)
  }

  const handleClickEdit = () => setIsModalEditProfileVisible(true)

  const handleMenuSelect = () => {}

  return (
    <>
      {isOwnProfile && (
        <>
          <ModalEditProfile
            show={isModalEditProfileVisible}
            onHide={handleClose}
          />
          <ModalFollowers
            activeTab={modalFollowersActiveTab}
            onHide={() => setModalFollowersActiveTab(false)}
          />
        </>
      )}
      <div
        {...rest}
        className={classNames(
          style.cardPersonal,
          { [style.variantProfilePage]: variant === 'profilePage' },
          className
        )}
      >
        <img
          className={style.banner}
          src={bannerImg}
          alt="profile background"
          width="100%"
          height="160px"
        />
        {isOwnProfile && (
          <MenuMoreActions
            menuItems={[
              {
                text: 'Share',
                icon: 'menu-share',
              },
              {
                text: 'Log out',
                icon: 'menu-log-out',
                variant: 'danger',
              },
            ]}
            onSelect={handleMenuSelect}
            className={style.btnMoreActions}
          />
        )}
        <div className={style.cardBody}>
          <img
            src={avatarImg}
            alt="avatar"
            width={160}
            height={160}
            className={style.avatar}
          />
          <div className={style.gridName}>
            <h5>{name}</h5>
            <p className={style.textNickname}>
              <span>@{username}</span>
              <Icon name="icon-verified" size={[16, 15]} />
            </p>
          </div>
          <InputCopy value={walletAddress} className={style.inputCopy} />
          <div className={style.gridStats}>
            <button
              className={style.btnStats}
              type="button"
              onClick={() => setModalFollowersActiveTab('followers')}
            >
              <p>Followers</p>
              <p>{followers}</p>
            </button>
            <button
              className={style.btnStats}
              type="button"
              onClick={() => setModalFollowersActiveTab('following')}
            >
              <p>Following</p>
              <p>{following}</p>
            </button>
          </div>
          <div className={style.gridButtons}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isOwnProfile ? (
              <>
                <Button variant="primary" as={Link} to={Route.CREATE_NFT}>
                  Create
                </Button>
                <Button variant="secondary" onClick={handleClickEdit}>
                  Edit Profile
                </Button>
              </>
            ) : isFollowed ? (
              <Button variant="secondary">Unfollow</Button>
            ) : (
              <Button variant="primary">Follow</Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

CardPersonal.defaultProps = {
  className: '',
  isOwnProfile: false,
  isFollowed: false,
  variant: 'profilePage',
}

CardPersonal.propTypes = {
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  className: PropTypes.string,
  isOwnProfile: PropTypes.bool,
  isFollowed: PropTypes.bool,
  bannerImg: PropTypes.string.isRequired,
  avatarImg: PropTypes.string.isRequired,
  followers: PropTypes.number.isRequired,
  following: PropTypes.number.isRequired,
  walletAddress: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['searchPage', 'profilePage']),
}

export default CardPersonal
