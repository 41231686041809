/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

import Layout from '~components/general/Layout'
import CardPersonal from '~components/general/CardPersonal'

import * as style from './profile.module.scss'
import S from '~components/seo'
import Auction from '~components/general/Auction'
import CardPersonalSocial from './components/CardPersonalSocial'
import NavAuctions from '~components/ui/NavAuctions'
import { MOCK_PROFILE_PAGE_AUCTIONS } from '~utils/mocks'
import { AuctionCategory } from '~utils/enums'
import Route from '~routes'

const noCache = Math.random()

/**
 * Profile component
 */
const ProfilePage = (props) => {
  const { data } = props
  const { site } = data

  const defaultAuctionsCategory = AuctionCategory.ON_SALE.key
  const [activeAuctionsCategoryKey, setActiveAuctionsCategoryKey] = useState(
    defaultAuctionsCategory
  )

  const handleNavSelection = (e) => setActiveAuctionsCategoryKey(e)

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.PROFILE}
    >
      <Container className={style.container}>
        <S title="Profile" />
        <CardPersonal
          name="John Smith"
          username="johnsmith"
          isOwnProfile
          bannerImg={`https://picsum.photos/1000/480?nocache=${noCache}`}
          avatarImg={`https://picsum.photos/480/480?nocache=${noCache}`}
          followers={10}
          following={120}
          walletAddress="0x9486371gd44js56d4k692dfk30dl0bC618148sC238"
        />
        <CardPersonalSocial
          socialLinks={[
            {
              icon: 'web',
              title: 'Personal website',
              text: 'johnsmith.com',
              href: '#0',
            },
            {
              icon: 'twitter',
              text: 'john_smith',
              href: '#1',
            },
            {
              icon: 'facebook',
              text: 'JohnSmith',
              href: '#2',
            },
            {
              icon: 'discord',
              text: 'jsmith',
              href: '#3',
            },
            {
              icon: 'instagram',
              text: 'john_smith',
              href: '#4',
            },
            {
              icon: 'twitch',
              text: 'john_smith_official',
              href: '#5',
            },
            {
              icon: 'tik-tok',
              title: 'Tik Tok',
              text: 'john_smith',
              href: '#6',
            },
          ]}
        />
        <section className={style.sectionAuction}>
          <NavAuctions
            onSelect={handleNavSelection}
            defaultActiveKey={defaultAuctionsCategory}
            auctions={MOCK_PROFILE_PAGE_AUCTIONS}
            filterEnum={AuctionCategory}
            filterField="category"
          />
          <div className={style.gridAuction}>
            {MOCK_PROFILE_PAGE_AUCTIONS.filter(
              (auction) => auction.category.key === activeAuctionsCategoryKey
            ).map((auction) => (
              <Auction key={auction.id} {...auction} />
            ))}
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default ProfilePage
