// extracted by mini-css-extract-plugin
export var columnInfo = "x_bB";
export var textHeading = "x_by";
export var marginLg = "x_bC";
export var textLoadingDotty = "x_bD";
export var dotty = "x_d";
export var textDescription = "x_R";
export var iconLoading = "x_bF";
export var rotate = "x_c";
export var connectWallet = "x_bG";
export var btnList = "x_bH";
export var inputPrice = "x_bJ";