import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './TopSellerItem.module.scss'

/**
 * SellerItem component
 */
const TopSellerItem = (props) => {
  const { className, rating, name, amount, currency, authorImgSrc, ...rest } =
    props

  return (
    <div {...rest} className={classNames(style.sellerItem, className)}>
      <p className={style.textRating}>{rating.toString().padStart(2, '0')}</p>
      <div className={style.avatar}>
        <img src={authorImgSrc} alt={name} width="100%" height="100%" />
      </div>
      <div className={style.colInfo}>
        <p className={style.textName}>{name}</p>
        <p className={style.textAmount}>
          {amount} {currency}
        </p>
      </div>
    </div>
  )
}

TopSellerItem.defaultProps = {
  className: '',
}

TopSellerItem.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  authorImgSrc: PropTypes.string.isRequired,
}

export default TopSellerItem
