/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import Auction from '~components/general/Auction'
import * as style from './MintPage.module.scss'
import BlockMinting from './components/mintingBlocks/BlockMinting'
import BlockListing from './components/mintingBlocks/BlockListing'
import BlockConfirmation from './components/mintingBlocks/BlockConfirmation'

const noCache = Math.random()

/**
 * MintPage component
 */
const MintPage = (props) => {
  const { data } = props
  const { site } = data

  const [nftStatus, setNftStatus] = useState('minting')

  const [nftPrice, setNftPrice] = useState()
  const [nftPriceStatus, setNftPriceStatus] = useState()

  const handlePriceSubmit = (formData) => {
    setNftStatus('confirmation')
    setNftPriceStatus('Current Bid')
    setNftPrice(formData.price)
  }

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.MINT_NFT}
    >
      <Container>
        <S title="Mint your NFT" />
        <div className={style.gridMint}>
          <Auction
            imgSrc={`https://picsum.photos/1000/1000?nocache=${noCache}`}
            authorImgSrc={`https://picsum.photos/96/96?nocache=${noCache}`}
            authorName="@johnsmith"
            title="Empire State Building"
            hasControls={false}
            isSkeleton
            price={nftPrice}
            priceStatus={nftPriceStatus}
            currency="TRAIL"
          />
          {nftStatus === 'minting' && (
            // TODO: Remove temporary behavior
            <BlockMinting onDoubleClick={() => setNftStatus('listing')} />
          )}
          {nftStatus === 'listing' && (
            <BlockListing onSubmit={handlePriceSubmit} />
          )}
          {nftStatus === 'confirmation' && <BlockConfirmation />}
        </div>
      </Container>
    </Layout>
  )
}

export default MintPage
