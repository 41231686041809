import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button } from 'react-bootstrap'
import * as style from './BtnWalletNav.module.scss'
import Icon from '~components/ui/Icon'

/**
 * BtnWalletNav component
 */
const BtnWalletNav = (props) => {
  const { className, variant, ...rest } = props

  return (
    <Button
      {...rest}
      variant="dark"
      type="button"
      className={classNames(style.btnWalletNav, className)}
    >
      <Icon
        name={`icon-wallet-chevron-${variant}`}
        size={20}
        className={classNames(style.icon)}
      />
    </Button>
  )
}

BtnWalletNav.defaultProps = {
  className: '',
}

BtnWalletNav.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['left', 'right']).isRequired,
}

export default BtnWalletNav
