/* eslint-disable react/prop-types */

import React, { useState } from 'react'

import { Button, Container } from 'react-bootstrap'
// eslint-disable-next-line no-unused-vars
import { Link } from 'gatsby'
import * as style from './SearchPage.module.scss'
import { AuctionStatus, SearchType } from '~utils/enums'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import InputSearch from '~components/ui/InputSearch'
import NavAuctions from '~components/ui/NavAuctions'
import { MOCK_CREATORS, MOCK_PROFILE_PAGE_AUCTIONS } from '~utils/mocks'
import Auction from '~components/general/Auction'
import CardPersonal from '~components/general/CardPersonal'
import Route from '~routes'

/**
 * SearchPage component
 */
const SearchPage = (props) => {
  const { data } = props
  const { site } = data

  const defaultAuctionsStatusKey = AuctionStatus.LIVE.key
  const [activeAuctionsStatusKey, setActiveAuctionsStatusKey] = useState(
    defaultAuctionsStatusKey
  )

  const [activeSearchType, setActiveSearchType] = useState(
    SearchType.CITY_COLLECTIBLES
  )

  const handleNavSelection = (e) => setActiveAuctionsStatusKey(e)

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.SEARCH}
    >
      <Container>
        <S title="Search" />
        <section className={style.sectionSearch}>
          <h2>Search</h2>
          <InputSearch
            variant="extended"
            className={style.inpSearchExtended}
            activeSearchType={activeSearchType}
            setActiveSearchType={setActiveSearchType}
          />
          <p className={style.textSearchStatus}>
            We found <span className={style.textResultsCount}>4</span> results
            for <span className={style.textQuery}>Pink</span>
          </p>
        </section>
        <section className={style.sectionResults}>
          {activeSearchType === SearchType.CITY_COLLECTIBLES && (
            <NavAuctions
              defaultActiveKey={defaultAuctionsStatusKey}
              auctions={MOCK_PROFILE_PAGE_AUCTIONS}
              onSelect={handleNavSelection}
              filterField="status"
              filterEnum={AuctionStatus}
            />
          )}
          {/* Temporary check for Mock data */}
          {[SearchType.CITY_COLLECTIBLES, SearchType.CREATOR].includes(
            activeSearchType
          ) ? (
            <div className={style.gridResults}>
              {activeSearchType === SearchType.CITY_COLLECTIBLES &&
                MOCK_PROFILE_PAGE_AUCTIONS.filter(
                  (auction) => auction.status.key === activeAuctionsStatusKey
                ).map(({ id, category, status, ...auctionProps }) => (
                  <Auction key={id} {...auctionProps} />
                ))}
              {activeSearchType === SearchType.CREATOR &&
                MOCK_CREATORS.map(({ uid, ...creatorProps }) => (
                  <CardPersonal
                    variant="searchPage"
                    key={uid}
                    {...creatorProps}
                  />
                ))}
            </div>
          ) : (
            <div className={style.blockNoResults}>
              <h2>No items found</h2>
              <p className={style.textDescription}>
                We couldn’t find what you’re looking for, check your spelling or
                try another search query
              </p>
              <Button
                variant="primary"
                as={Link}
                to="/"
                className={style.btnLink}
              >
                Explore
              </Button>
            </div>
          )}
        </section>
      </Container>
    </Layout>
  )
}

export default SearchPage
