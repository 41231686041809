import React from 'react'
import PropTypes from 'prop-types'
import { FormLabel, FormText, FormControl, InputGroup } from 'react-bootstrap'
import classNames from 'classnames'

/**
 * InputWLabel component
 */
const InputWLabel = React.forwardRef((props, ref) => {
  const {
    id,
    className,
    style,
    label,
    hint,
    errors,
    name,
    variant,
    classNameFormControl,
    ...rest
  } = props

  return (
    <div className={className} style={style}>
      {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup
        className={classNames({ [`input-group--${variant}`]: variant })}
      >
        <FormControl
          {...rest}
          id={id}
          ref={ref}
          name={name}
          className={classNameFormControl}
        />
      </InputGroup>
      {!!errors?.[name] && (
        <FormText className="form-text--error">{errors[name].message}</FormText>
      )}
      {!!hint && <FormText>{hint}</FormText>}
    </div>
  )
})

InputWLabel.defaultProps = {
  className: null,
  classNameFormControl: null,
  label: null,
  hint: null,
  style: null,
  errors: {},
  variant: null,
}

InputWLabel.propTypes = {
  className: PropTypes.string,
  classNameFormControl: PropTypes.string,
  style: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['username']),
}

export default InputWLabel
