// extracted by mini-css-extract-plugin
export var ownerAvatar = "J_c3";
export var cardImageWrapper = "J_c4";
export var cardImage = "J_c5";
export var rarity = "J_c6";
export var cardText = "J_c7";
export var priceStatus = "J_c8";
export var price = "J_c9";
export var footerIcon = "J_db";
export var itemSkeleton = "J_dc";
export var rotate = "J_c";
export var dotty = "J_d";