/* eslint-disable react/prop-types */

import React from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import AuctionDetails from './components/AuctionDetails'
import { MOCK_AUCTION_DETAILS_ITEM } from '~utils/mocks'

/**
 * Auction component
 */
const Auction = (props) => {
  const { data } = props
  const { site } = data

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.AUCTION}
    >
      <Container>
        <S title="Auction" />
        <AuctionDetails {...MOCK_AUCTION_DETAILS_ITEM} />
      </Container>
    </Layout>
  )
}

export default Auction
