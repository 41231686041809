import { useCallback, useState } from 'react'
import clamp from 'lodash/clamp'
import { inverseLerp } from '~utils'

/**
 * @typedef useScrollSegmentToAlphaReturnType {Object}
 * @property {function(*): void} handleScroll  - Scroll handle function
 * @property {number} alpha - Alpha value [0 - 1]
 */

/**
 * @param {number} start - Starting scroll position [0 - 1] that corresponds to alpha value of 0
 * @param {number} end - End scroll position [0 - 1] that corresponds to alpha value of 1
 * @returns {useScrollSegmentToAlphaReturnType}
 */
const useScrollSegmentToAlpha = (start, end) => {
  const [alpha, setAlpha] = useState(1)

  const handleScroll = useCallback((e) => {
    const scrollContainerEl = e.currentTarget

    if (!scrollContainerEl) return

    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainerEl

    // horizontal scroll percent 0 - 1
    const scrollPercent = scrollLeft / (scrollWidth - offsetWidth)

    // inverseInterpolating scroll value to control blendOverlay opacity
    // `start` of scroll - alpha: 1
    // `end` of scroll - alpha: 0
    const alphaValue = clamp(inverseLerp(start, end, scrollPercent), 0, 1)

    setAlpha(alphaValue)
  }, [])

  return { handleScroll, alpha }
}

export default useScrollSegmentToAlpha
