// extracted by mini-css-extract-plugin
export var sectionSearch = "l_H";
export var inpSearchExtended = "l_J";
export var textSearchStatus = "l_K";
export var textResultsCount = "l_L";
export var textQuery = "l_M";
export var sectionResults = "l_N";
export var gridResults = "l_P";
export var blockNoResults = "l_Q";
export var textDescription = "l_R";
export var btnLink = "l_S";
export var rotate = "l_c";
export var dotty = "l_d";