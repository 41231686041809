/* eslint-disable react/prop-types */

import React from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'

import * as style from './CreateNftPage.module.scss'
import BlockCreateNft from './components/BlockCreateNft'

/**
 * CreateNftPage component
 */
const CreateNftPage = (props) => {
  const { data } = props
  const { site } = data

  return (
    <>
      <Layout
        siteTitle={site.siteMetadata.title}
        isLoggedIn
        activePage={Route.CREATE_NFT}
      >
        <Container>
          <S title="Create an NFT" />
          <h2 className={style.pageHeading}>Create an NFT</h2>
          <BlockCreateNft />
        </Container>
      </Layout>
    </>
  )
}

export default CreateNftPage
