import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Nav,
  TabContainer,
  TabContent,
  TabPane,
  ToastContainer,
} from 'react-bootstrap'

import * as style from './CardWalletActions.module.scss'
import BlockWalletConvert from '../WalletActions/BlockWalletConvert'
import BlockWalletSend from '../WalletActions/BlockWalletSend'
import ToastCustom from '../ToastCustom'

/**
 * CardWalletActions component
 */
const CardWalletActions = (props) => {
  const { className, ...rest } = props

  const [toastStack, setToastStack] = useState([])

  const handleSubmitConvert = (data) => {
    if (parseFloat(data.amountFrom) > parseFloat(data.walletFrom.amountCoins)) {
      setToastStack((prevState) => [...prevState, 'balanceExceeded'])
      return
    }

    setToastStack((prevState) => [...prevState, 'success'])
  }

  const handleSubmitSend = (data) => {
    if (parseFloat(data.amountFrom) > parseFloat(data.walletFrom.amountCoins)) {
      setToastStack((prevState) => [...prevState, 'balanceExceeded'])
      return
    }

    setToastStack((prevState) => [...prevState, 'success'])
  }

  return (
    <>
      <div {...rest} className={classNames(style.cardWalletActions, className)}>
        <TabContainer defaultActiveKey="convert">
          <Nav variant="cardHeader" defaultActiveKey="convert">
            <Nav.Item>
              <Nav.Link eventKey="convert" as="button">
                Convert
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="send" as="button">
                Send
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <TabContent className={style.tabContainerWalletActions}>
            <TabPane eventKey="convert">
              <BlockWalletConvert onSubmit={handleSubmitConvert} />
            </TabPane>
            <TabPane eventKey="send">
              <BlockWalletSend onSubmit={handleSubmitSend} />
            </TabPane>
          </TabContent>
        </TabContainer>
      </div>
      <ToastContainer className={style.toastContainer}>
        {toastStack.map((toastType) => (
          <ToastCustom type={toastType} show />
        ))}
      </ToastContainer>
    </>
  )
}

CardWalletActions.defaultProps = {
  className: '',
}

CardWalletActions.propTypes = {
  className: PropTypes.string,
}

export default CardWalletActions
