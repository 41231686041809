import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { gsap } from 'gsap'
import { useInView } from 'react-intersection-observer'
import * as style from './CarouselWallet.module.scss'
import BtnWalletNav from '../BtnWalletNav'
import { MOCK_WALLETS } from '~utils/mocks'
import CardWallet from '../CardWallet'
import { handleScrollXToItem } from '~utils'

/**
 * CarouselWallet component
 */
const CarouselWallet = (props) => {
  const { activeWallet, setActiveWallet, headingText } = props

  const scrollContainerRef = useRef()

  const walletCardWidth = 172
  const colGap = 20

  const handleMoveWalletStrip = (direction = 1) => {
    gsap.to(scrollContainerRef.current, {
      duration: 0.5,
      scrollTo: {
        x:
          scrollContainerRef.current.scrollLeft +
          direction * (walletCardWidth + colGap),
      },
    })
  }

  const handleWalletClick = (e, wallet) => {
    setActiveWallet(wallet)
    handleScrollXToItem(e, scrollContainerRef, colGap)
  }

  const [firstWalletRef, firstWalletInView] = useInView({ threshold: 1 })
  const [lastWalletRef, lastWalletInView] = useInView({ threshold: 1 })

  return (
    <>
      <div className={style.rowPageHeading}>
        <h2>{headingText}</h2>
        <div className={style.gridNavButtons}>
          <BtnWalletNav
            variant="left"
            onClick={() => handleMoveWalletStrip(-1)}
            disabled={firstWalletInView}
          />
          <BtnWalletNav
            variant="right"
            onClick={() => handleMoveWalletStrip(1)}
            disabled={lastWalletInView}
          />
        </div>
      </div>
      <div className={style.blockWallets}>
        <div
          className={classNames(style.blendShadowRight, {
            [style.hidden]: lastWalletInView,
          })}
        />
        <div
          className={classNames(style.blendShadowLeft, {
            [style.hidden]: firstWalletInView,
          })}
        />
        <div className={style.gridWallets} ref={scrollContainerRef}>
          <div className={style.gridWalletsInner}>
            {MOCK_WALLETS.map((wallet, index) => (
              <CardWallet
                key={wallet.id}
                activeWallet={activeWallet}
                onClick={(e) => handleWalletClick(e, wallet)}
                ref={
                  // eslint-disable-next-line no-nested-ternary
                  index === 0
                    ? firstWalletRef
                    : index === MOCK_WALLETS.length - 1
                    ? lastWalletRef
                    : null
                }
                {...wallet}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

CarouselWallet.defaultProps = {
  className: '',
}

CarouselWallet.propTypes = {
  className: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  activeWallet: PropTypes.object.isRequired,
  setActiveWallet: PropTypes.func.isRequired,
}

export default CarouselWallet
