/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import LocationsFilterBar from './components/LocationsFilterBar'
import Layout from '~components/general/Layout'
import InputSearch from '~components/ui/InputSearch'
import S from '~components/seo'
import NavAuctions from '~components/ui/NavAuctions'
import Auction from '~components/general/Auction'
import { MOCK_PROFILE_PAGE_AUCTIONS } from '~utils/mocks'
import { AuctionStatus } from '~utils/enums'
import * as style from './CityCollectibles.module.scss'

/**
 * CityCollectibles component
 */
const CityCollectibles = (props) => {
  const { data } = props
  const { site } = data

  const defaultAuctionsStatusKey = AuctionStatus.LIVE.key
  const [activeAuctionsStatusKey, setActiveAuctionsStatusKey] = useState(
    defaultAuctionsStatusKey
  )

  const [activeCountry, setActiveCountry] = useState()
  const [activeCityIds, setActiveCityIds] = useState([])

  const handleNavSelection = (e) => setActiveAuctionsStatusKey(e)

  const filterAuctions = (auction) => {
    const statusMatch = auction.status.key === activeAuctionsStatusKey

    const countryMatch = activeCountry
      ? auction.countryId === activeCountry?.id
      : true

    const cityMatch = activeCityIds.length
      ? activeCityIds.includes(auction.cityId)
      : true

    return statusMatch && countryMatch && cityMatch
  }

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.CITY_COLLECTIBLES}
    >
      <Container>
        <S title="City Collectibles" />
        <h2 className={style.pageHeading}>City Collectibles</h2>
        <div className={style.gridSearch}>
          <InputSearch
            placeholder="Search by country or city"
            className={style.inpSearch}
            isSearchFilter
          />
          <LocationsFilterBar
            activeCountry={activeCountry}
            setActiveCountry={setActiveCountry}
            activeCityIds={activeCityIds}
            setActiveCityIds={setActiveCityIds}
            filterAuctions={filterAuctions}
          />
        </div>
        <hr className={style.divider} />
        <div className={style.gridNav}>
          <NavAuctions
            defaultActiveKey={defaultAuctionsStatusKey}
            auctions={MOCK_PROFILE_PAGE_AUCTIONS}
            onSelect={handleNavSelection}
            filterField="status"
            filterEnum={AuctionStatus}
            showCounts={false}
            showBlendShadow={false}
          />
        </div>
        <div className={style.gridAuctions}>
          {MOCK_PROFILE_PAGE_AUCTIONS.filter(filterAuctions).map(
            ({ id, category, status, ...auctionProps }) => (
              <Auction key={id} {...auctionProps} />
            )
          )}
        </div>
      </Container>
    </Layout>
  )
}
export default CityCollectibles
