// extracted by mini-css-extract-plugin
export var bidCountdown = "bh_cY";
export var columnBid = "bh_f3";
export var infoColumnTitle = "bh_f4";
export var countdownGrid = "bh_f5";
export var currentBidGrid = "bh_f6";
export var currencyName = "bh_f7";
export var heroCard = "bh_b5";
export var card = "bh_f8";
export var textPrice = "bh_f9";
export var rotate = "bh_c";
export var dotty = "bh_d";