import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Nav } from 'react-bootstrap'
import classNames from 'classnames'

import { countBy } from 'lodash'
import * as style from './NavAuctions.module.scss'
import BlendFadeShadow from '~components/ui/BlendFadeShadow'

/**
 * NavAuctions component
 */
const NavAuctions = (props) => {
  const {
    className,
    onSelect,
    defaultActiveKey,
    auctions,
    filterField,
    filterEnum,
    showCounts,
    showBlendShadow,
    ...rest
  } = props

  const scrollContainerRef = useRef()

  const auctionCounts = showCounts
    ? countBy(auctions, (auction) => auction[filterField].key)
    : {}

  return (
    <div {...rest} className={classNames(style.navProfileRoot, className)}>
      {showBlendShadow && (
        <BlendFadeShadow
          className={style.blendShadow}
          fadeStart={0.82}
          scrollContainerRef={scrollContainerRef}
          fadeEnd={0.9}
        />
      )}
      <div className={style.navProfileScrollContainer} ref={scrollContainerRef}>
        <Nav
          className={style.navProfile}
          variant="white"
          defaultActiveKey={defaultActiveKey}
          onSelect={onSelect}
        >
          {Object.values(filterEnum).map((filterKey) => (
            <Nav.Item key={filterKey.key}>
              <Nav.Link
                eventKey={filterKey.key}
                as="button"
                data-has-notification={filterKey.hasNotification}
              >
                <span className="nav-link-text-name">{filterKey.name}</span>
                {showCounts && (
                  <span className="nav-link-text-count">
                    {auctionCounts[filterKey.key]}
                  </span>
                )}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </div>
  )
}

NavAuctions.defaultProps = {
  className: '',
  showCounts: true,
  showBlendShadow: true,
}

NavAuctions.propTypes = {
  className: PropTypes.string,
  auctions: PropTypes.array.isRequired,
  defaultActiveKey: PropTypes.string.isRequired,
  filterField: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  filterEnum: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      hasNotification: PropTypes.bool,
    })
  ).isRequired,
  showCounts: PropTypes.bool,
  showBlendShadow: PropTypes.bool,
}

export default NavAuctions
