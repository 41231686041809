import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useForm, Controller } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './BidModal.module.scss'
import InputBidding from '../InputBidding'
import { maskNumberValue } from '~utils'

/**
 * BidModal component
 */
const BidModal = (props) => {
  const { show, onHide, className, ...rest } = props

  const minBid = 13.4627

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const bidAmount = watch(['bidAmount'])

  const onSubmit = () => {
    onHide()
  }

  const handleMinBidClick = () => {
    setValue('bidAmount', minBid)
  }

  const transformBidAmount = (e) => {
    return maskNumberValue(e.target.value)
  }

  return (
    <Modal
      {...rest}
      show={show}
      onHide={onHide}
      scrollable
      contentClassName={classNames(style.modalBid, className)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Place a bid</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={style.modalBody}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={style.blockInner}>
          <p className={style.textSup}>You must bid at least</p>
          <h5>{minBid} katyperry coins</h5>
          <Controller
            name="bidAmount"
            control={control}
            rules={{
              required: 'Please, specify the bid amount',
              min: {
                value: minBid,
                message: 'Your bid must be higher than the minimum bid',
              },
              // max: {
              //   value: 9999,
              //   message: 'The bid must be lower than your balance',
              // },
            }}
            render={({ field: { onChange, ...fieldProps } }) => (
              <InputBidding
                {...fieldProps}
                id="inp-bid"
                label="Your bid"
                placeholder="Enter your bid"
                autoComplete="off"
                inputMode="decimal"
                onMinBidClick={handleMinBidClick}
                isMinBid={minBid.toString() === bidAmount.toString()}
                onChange={(e) => onChange(transformBidAmount(e))}
                errors={errors}
                valueConverted="$0.00"
              />
            )}
          />

          <hr />
          <div className={style.gridDetails}>
            <p>Your Balance</p>
            <p>200.3743423 TRAIL</p>
            <p>Bid Conversion</p>
            <p>1.4832 TRAIL</p>
            <p>After Payment</p>
            <p>198.8911423 TRAIL</p>
          </div>
          <div className={style.blockBottom}>
            <Button variant="primary" type="submit" className={style.btnBid}>
              Place a bid
            </Button>
            <div>
              <p className={style.textBottom}>
                Once a bid is placed, it cannot be withdrawn.
              </p>
              <Link to="/" className={style.textBottomLink}>
                Learn how it works
              </Link>
            </div>
          </div>
        </div>
      </Modal.Body>
      <DevTool control={control} />
    </Modal>
  )
}

BidModal.defaultProps = {
  className: '',
}

BidModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default BidModal
