/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import * as style from './CommunityUpvote.module.scss'
import BlockVerification from './components/BlockVerification'
import {
  COMMUNITY_UPVOTE_HEADING,
  COMMUNITY_UPVOTE_PARAGRAPH,
} from './constants'
import BlockMain from './components/BlockCommunityVotes'

/**
 * CommunityUpvotePage component
 */
const CommunityUpvotePage = (props) => {
  const { data } = props
  const { site } = data

  // Temporary behavior
  const [isVerified, setIsVerified] = useState(false)

  return (
    <>
      <Layout
        siteTitle={site.siteMetadata.title}
        isLoggedIn
        activePage={Route.COMMUNITY_UPVOTE}
      >
        <Container>
          <S title="Community Upvote" />
          <div
            className={classNames(style.gridHeading, {
              [style.isUnverified]: !isVerified,
            })}
          >
            <div
              className={classNames(style.blockHeading, {
                [style.isVerified]: isVerified,
              })}
            >
              <h2>
                {
                  COMMUNITY_UPVOTE_HEADING[
                    isVerified ? 'verified' : 'unVerified'
                  ]
                }
              </h2>
              <p className={style.textComment}>
                Thank you for joining the Community Upvote.
                <br />
                {
                  COMMUNITY_UPVOTE_PARAGRAPH[
                    isVerified ? 'verified' : 'unVerified'
                  ]
                }
              </p>
              {!isVerified && (
                <BlockVerification
                  setIsVerified={setIsVerified}
                  className={style.btnJoin}
                />
              )}
            </div>
            {isVerified && (
              <div className={style.cardPosition}>
                <p className={style.textSup}>Your position</p>
                <h2 className={style.textPos}>19,987</h2>
                <p className={style.textSub}>out of 20,000</p>
              </div>
            )}
          </div>
          <BlockMain isVerified={isVerified} />
        </Container>
      </Layout>
    </>
  )
}

export default CommunityUpvotePage
