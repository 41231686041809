// extracted by mini-css-extract-plugin
export var auctionDetails = "G_cv";
export var blockDetails = "G_cw";
export var imgWrapper = "G_cx";
export var imgAspectRatioContainer = "G_cy";
export var img = "G_cz";
export var heading = "G_bv";
export var gridHead = "G_cB";
export var rowInStock = "G_cC";
export var badgeInStock = "G_cD";
export var menuMoreActions = "G_cF";
export var rowFigures = "G_cG";
export var gridPrice = "G_cH";
export var textPriceCoins = "G_cJ";
export var textPriceUsd = "G_cK";
export var textPercentToCreator = "G_cL";
export var textWhite = "G_cM";
export var description = "G_cN";
export var nav = "G_cP";
export var tabContainer = "G_cQ";
export var tabDetailsContent = "G_cR";
export var tabHistoryContent = "G_cS";
export var stickyContainer = "G_cT";
export var cardCountdownWrapper = "G_cV";
export var cardCountdownLg = "G_cW";
export var cardCountdown = "G_cX";
export var bidCountdown = "G_cY";
export var rotate = "G_c";
export var dotty = "G_d";