// extracted by mini-css-extract-plugin
export var blockWalletAction = "T_d3";
export var textSup = "T_x";
export var textSub = "T_z";
export var textSubSend = "T_d4";
export var gridWallet = "T_d5";
export var gridCurrency = "T_d6";
export var separatorConvert = "T_d7";
export var btnSeparator = "T_d8";
export var iconSeparator = "T_d9";
export var separatorSend = "T_fb";
export var btnSubmit = "T_fc";
export var inputRecipient = "T_fd";
export var rotate = "T_c";
export var dotty = "T_d";