import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormCheck } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './NotificationFilters.module.scss'
import { NotificationCategory } from '~utils/enums'

/**
 * NotificationFilters component
 */
const NotificationFilters = (props) => {
  const { className, notificationsFilter, setNotificationsFilter, ...rest } =
    props

  const { register, watch, control } = useForm({
    defaultValues: { notificationCategories: notificationsFilter },
  })
  const appliedFilters = watch('notificationCategories')

  useEffect(() => {
    setNotificationsFilter(appliedFilters)
  }, [appliedFilters])

  return (
    <div {...rest} className={classNames(style.notificationFilters, className)}>
      <h5 className={style.heading}>Filter</h5>
      <div className={style.gridCheckboxes}>
        {Object.entries(NotificationCategory).map(([key, value]) => (
          <FormCheck
            key={key}
            id={`inp-checkbox-${key}`}
            label={value}
            value={value}
            {...register('notificationCategories')}
          />
        ))}
      </div>
      <DevTool control={control} />
    </div>
  )
}

NotificationFilters.defaultProps = {
  className: '',
}

NotificationFilters.propTypes = {
  className: PropTypes.string,
  notificationsFilter: PropTypes.array.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
}

export default NotificationFilters
