import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import * as style from './mintingBlocks.module.scss'
import ConnectedWallet from '../ConnectedWallet'
import Icon from '~components/ui/Icon'

/**
 * BlockMinting component
 */
const BlockMinting = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.columnInfo, className)}>
      <h1 className={style.textHeading}>Mint your NFT</h1>
      <p className={style.textDescription}>
        Confirm this transaction with your wallet to continue. Doing this will
        sign your wallet as the original creator of the NFT.
      </p>
      <ConnectedWallet className={style.connectWallet} />
      <Icon
        name="icon-nft-mint-loading"
        size={60}
        className={style.iconLoading}
      />
    </div>
  )
}

BlockMinting.defaultProps = {
  className: '',
}

BlockMinting.propTypes = {
  className: PropTypes.string,
}

export default BlockMinting
