import React from 'react'
import { Router } from '@gatsbyjs/reach-router'
import { graphql } from 'gatsby'
import WalletPage from '~containers/Wallet'
import PrivateRoute from '~components/general/PrivateRoute'
import NotificationsPage from '~containers/Notifications'
import NotFoundPage from '~pages/404'
import Route from '~routes'
import Homepage from '~containers/Homepage'
import ProfilePage from '~containers/Profile'
import SearchPage from '~containers/Search'
import FollowingPage from '~containers/Following'
import CityCollectibles from '~containers/CityCollectibles'
import Auction from '~containers/Auction'
import LiveAuctionsPage from '~containers/LiveAuctions'
import CommunityUpvotePage from '~containers/CommunityUpvote'
import CreateNftPage from '~containers/CreateNFT'
import MintPage from '~containers/Mint'
import S from '~components/seo'

const RoutesPage = (props) => {
  if (typeof window === 'undefined') return <S />

  return (
    <Router basepath="/">
      <Homepage {...props} exact path={Route.HOMEPAGE} />
      <SearchPage {...props} exact path={Route.SEARCH} />
      <CityCollectibles {...props} exact path={Route.CITY_COLLECTIBLES} />
      <Auction {...props} path={`${Route.AUCTION}:id`} />
      <PrivateRoute
        {...props}
        exact
        path={Route.PROFILE}
        component={ProfilePage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.NOTIFICATIONS}
        component={NotificationsPage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.WALLET}
        component={WalletPage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.FOLLOWING}
        component={FollowingPage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.LIVE_AUCTIONS}
        component={LiveAuctionsPage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.COMMUNITY_UPVOTE}
        component={CommunityUpvotePage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.CREATE_NFT}
        component={CreateNftPage}
      />
      <PrivateRoute
        {...props}
        exact
        path={Route.MINT_NFT}
        component={MintPage}
      />
      <NotFoundPage default />
    </Router>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default RoutesPage
