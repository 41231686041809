import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

import * as style from './CardPersonalSocial.module.scss'
import Icon from '~components/ui/Icon'

/**
 * CardPersonalSocial component
 */
const CardPersonalSocial = (props) => {
  const { className, socialLinks, ...rest } = props

  return (
    <div {...rest} className={classNames(style.cardPersonalSocial, className)}>
      <p className={style.textHeading}>Social links</p>
      <div className={style.gridLinks}>
        {socialLinks.map((socialLink) => (
          <a
            key={socialLink.icon}
            href={socialLink.href}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={style.linkSocial}
            title={socialLink.title ?? upperFirst(socialLink.icon)}
          >
            <Icon name={`icon-${socialLink.icon}`} size={24} />
            <p>{socialLink.text}</p>
          </a>
        ))}
      </div>
    </div>
  )
}

CardPersonalSocial.defaultProps = {
  className: '',
}

CardPersonalSocial.propTypes = {
  className: PropTypes.string,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string,
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CardPersonalSocial
