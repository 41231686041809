import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Card } from 'react-bootstrap'

import * as style from './Auction.module.scss'
import Icon from '~components/ui/Icon'
import MenuMoreActions from '~components/ui/MenuMoreActions'
import { AUCTION_MENU_ITEMS } from '~utils/constants'

// eslint-disable-next-line react/prop-types
const ItemSkeleton = ({ width }) => (
  <div className={style.itemSkeleton} style={{ width }} />
)

/**
 * Auction component
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1559%3A9608}
 * TODO: finish component
 */
const Auction = (props) => {
  const {
    className,
    authorImgSrc,
    authorName,
    imgSrc,
    title,
    priceStatus,
    price,
    timing,
    rarity,
    hasControls,
    footerIcon,
    countryId,
    cityId,
    timestampCreated,
    isSkeleton,
    currency,
    ...rest
  } = props

  const handleMenuSelect = () => {}

  return (
    <Card {...rest} className={className}>
      <Card.Header>
        <div className={style.ownerAvatar}>
          <img src={authorImgSrc} alt={authorName} width={32} height={32} />
        </div>
        <p>{authorName}</p>
        {hasControls && (
          <MenuMoreActions
            menuItems={AUCTION_MENU_ITEMS}
            onSelect={handleMenuSelect}
          />
        )}
      </Card.Header>
      <div className={style.cardImageWrapper}>
        <Badge bg={rarity} className={style.rarity}>
          {rarity}
        </Badge>
        <img src={imgSrc} alt={title} className={style.cardImage} />
      </div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className={style.cardText}>
          {!isSkeleton || priceStatus ? (
            <p className={style.priceStatus}>{priceStatus}</p>
          ) : (
            <ItemSkeleton width="102px" />
          )}
          {!isSkeleton || price ? (
            <p className={style.price}>
              {price} {currency}
            </p>
          ) : (
            <ItemSkeleton width="64px" />
          )}
        </div>
      </Card.Body>
      <Card.Footer className="text-muted">
        <div className="card-footer-content">
          {!!footerIcon && (
            <Icon name={footerIcon} size={16} className={style.footerIcon} />
          )}
          {!isSkeleton ? <span>{timing}</span> : <ItemSkeleton width="172px" />}
        </div>
      </Card.Footer>
    </Card>
  )
}

Auction.defaultProps = {
  className: '',
  rarity: null,
  footerIcon: null,
  hasControls: false,
  countryId: null,
  cityId: null,
  timestampCreated: null,
  isSkeleton: false,
  price: null,
  priceStatus: null,
  timing: null,
  currency: 'TRAIL',
}

Auction.propTypes = {
  className: PropTypes.string,
  authorImgSrc: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  priceStatus: PropTypes.string,
  price: PropTypes.number,
  timestampCreated: PropTypes.number,
  timing: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rarity: PropTypes.oneOf(['common', 'rare', 'ultra']),
  hasControls: PropTypes.bool,
  isSkeleton: PropTypes.bool,
  footerIcon: PropTypes.string,
  countryId: PropTypes.any,
  cityId: PropTypes.any,
  currency: PropTypes.string,
}

export default Auction
