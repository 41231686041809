/* eslint react/prop-types: 0 */
import React from 'react'
import { Button, Container } from 'react-bootstrap'
import cn from 'classnames'
import { Link } from 'gatsby'

import Layout from '~components/general/Layout'
import HeroCard from './components/HeroCard'
import Auction from '~components/general/Auction'
import TopSellerSection from './components/TopSellerSection'
import S from '~components/seo'

import * as style from './index.module.scss'
import Route from '~routes'

const AuctionExample = () => (
  <Auction
    authorImgSrc={`https://picsum.photos/96/96?nocache=${Math.random()}`}
    authorName="@johnsmith"
    imgSrc={`https://picsum.photos/1000/1000?nocache=${Math.random()}`}
    title="Empire State Building"
    priceStatus="Reserved Price"
    price={4}
    footerIcon="icon-clock"
    timing="Ends in 6h 20m 35s"
  />
)

/**
 * IndexPage component
 */
const Homepage = (props) => {
  const { data } = props
  const { site } = data

  return (
    <Layout siteTitle={site.siteMetadata.title}>
      <Container>
        <S title="Explore" />
        <HeroCard
          title="Flatiron Building"
          authorName="@johnsmith"
          authorImgSrc={`https://picsum.photos/96/96?nocache=${Math.random()}`}
          imgSrc={`https://picsum.photos/1000/1000?nocache=${Math.random()}`}
          auctionId={10001}
        />
        <section className={style.section}>
          <TopSellerSection />
        </section>
        <section className={style.section}>
          <div className={style.sectionHeading}>
            <h3>Live Auctions</h3>
            <Button
              variant="outline-secondary"
              as={Link}
              to={Route.LIVE_AUCTIONS}
            >
              View All
            </Button>
          </div>
          <div className={style.auctionDeck}>
            <AuctionExample />
            <AuctionExample />
            <AuctionExample />
            <AuctionExample />
          </div>
        </section>
        <section className={style.section}>
          <div className={style.sectionHeading}>
            <h3>Explore</h3>
          </div>
          <div className={cn(style.auctionDeck, style.exploreDeck)}>
            <AuctionExample />
            <AuctionExample />
            <AuctionExample />
            <AuctionExample />
          </div>
        </section>
        <div className={style.rowBtnLoadMore}>
          <Button variant="outline-secondary">Load more</Button>
        </div>
      </Container>
    </Layout>
  )
}

export default Homepage
