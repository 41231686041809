import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import * as style from './ItemFollower.module.scss'

/**
 * ItemFollower component
 */
const ItemFollower = (props) => {
  const { className, img, name, username, isFollowed, ...rest } = props

  return (
    <div {...rest} className={classNames(style.itemFollower, className)}>
      <img src={img} alt={name} className={style.img} />
      <div>
        <p className={style.textName}>{name}</p>
        <p className={style.textUsername}>@{username}</p>
      </div>
      {isFollowed ? (
        <Button variant="outline-secondary" className={style.btn}>
          Unfollow
        </Button>
      ) : (
        <Button variant="secondary" className={style.btn}>
          Follow
        </Button>
      )}
    </div>
  )
}

ItemFollower.defaultProps = {
  className: '',
  isFollowed: false,
}

ItemFollower.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  isFollowed: PropTypes.bool,
}

export default ItemFollower
