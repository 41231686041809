/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

import Route from '~routes'
import S from '~components/seo'
import Layout from '~components/general/Layout'
import NavAuctions from '~components/ui/NavAuctions'
import { MOCK_PROFILE_PAGE_AUCTIONS } from '~utils/mocks'
import { AuctionStatus } from '~utils/enums'
import * as style from './FollowingPage.module.scss'
import InputSearch from '~components/ui/InputSearch'
import CardNoMatches from '../../components/general/CardNoMatches'
import Auction from '~components/general/Auction'
import { objectFieldMatchesSearch } from '~utils'

/**
 * FollowingPage component
 */
const FollowingPage = (props) => {
  const { data } = props
  const { site } = data

  const defaultAuctionsStatusKey = AuctionStatus.LIVE.key
  const [activeAuctionsStatusKey, setActiveAuctionsStatusKey] = useState(
    defaultAuctionsStatusKey
  )
  const [searchQuery, setSearchQuery] = useState('')

  const followingAuctionsFilter = (auction) => {
    const statusMatch = auction.status.key === activeAuctionsStatusKey

    let searchQueryMatches = true

    if (searchQuery) {
      searchQueryMatches =
        objectFieldMatchesSearch(auction.authorName, searchQuery) ||
        objectFieldMatchesSearch(auction.title, searchQuery)
    }

    return statusMatch && searchQueryMatches
  }

  const handleNavSelection = (e) => setActiveAuctionsStatusKey(e)

  const filteredAuctions = MOCK_PROFILE_PAGE_AUCTIONS.filter(
    followingAuctionsFilter
  )

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.FOLLOWING}
    >
      <Container>
        <S title="Following" />
        <div className={style.gridHeading}>
          <h2 className={style.pageHeading}>Following</h2>
          <InputSearch placeholder="Search" className={style.inpSearch} />
        </div>
        <div className={style.gridNav}>
          <NavAuctions
            defaultActiveKey={defaultAuctionsStatusKey}
            auctions={MOCK_PROFILE_PAGE_AUCTIONS}
            onSelect={handleNavSelection}
            filterField="status"
            filterEnum={AuctionStatus}
            showCounts={false}
            showBlendShadow={false}
          />
          <InputSearch
            placeholder="Search"
            className={style.inpSearch}
            value={searchQuery}
            isSearchFilter
            onChange={(e) => setSearchQuery(e.target.value)}
            onClear={() => setSearchQuery('')}
          />
        </div>
        {filteredAuctions.length ? (
          <div className={style.gridAuctions}>
            {filteredAuctions.map(
              ({ id, category, status, ...auctionProps }) => (
                <Auction key={id} {...auctionProps} />
              )
            )}
          </div>
        ) : (
          <CardNoMatches />
        )}
      </Container>
    </Layout>
  )
}

export default FollowingPage
