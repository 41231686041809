// extracted by mini-css-extract-plugin
export var heroCard = "C_b5";
export var heroCardInfo = "C_b6";
export var heroImageWrapper = "C_b7";
export var heroImageAspectRatioContainer = "C_b8";
export var heroImage = "C_b9";
export var authorBadge = "C_cb";
export var authorAvatar = "C_cc";
export var authorName = "C_cd";
export var heading = "C_bv";
export var blockCountdown = "C_cf";
export var buttonRow = "C_cg";
export var rotate = "C_c";
export var dotty = "C_d";