import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'gatsby'
import dayjs from 'dayjs'
import * as style from './AuctionHistoryItem.module.scss'
import { AuctionHistoryItemType } from '~utils/enums'

/**
 * AuctionHistoryItem component
 */
const AuctionHistoryItem = (props) => {
  const { className, type, user, price, timestamp, ...rest } = props

  const getDescriptionText = () => {
    switch (type) {
      case AuctionHistoryItemType.MINTED:
        return (
          <>
            Minted by{' '}
            <Link to={user.profileUrl} className={style.textUserLink}>
              @{user.name}
            </Link>
          </>
        )
      case AuctionHistoryItemType.LISTED:
        return (
          <>
            Listed by{' '}
            <Link to={user.profileUrl} className={style.textUserLink}>
              @{user.name}
            </Link>{' '}
            for{' '}
            <span className={style.textPrice}>
              {price.amount} {price.currencyName}
            </span>
          </>
        )
      case AuctionHistoryItemType.BID_PLACED:
        return (
          <>
            Bid placed by{' '}
            <Link to={user.profileUrl} className={style.textUserLink}>
              @{user.name}
            </Link>{' '}
            for{' '}
            <span className={style.textPrice}>
              {price.amount} {price.currencyName}
            </span>
          </>
        )
      default:
        return ''
    }
  }

  return (
    <div {...rest} className={classNames(style.auctionHistoryItem, className)}>
      <Link to={user.profileUrl} className={style.imgWrapper}>
        <img src={user.img} alt={user.name} className={style.img} />
      </Link>
      <p className={style.textDesc}>{getDescriptionText()}</p>
      <p className={style.textDate}>
        {`${dayjs.unix(timestamp).format('MMMM D, YYYY [at] hh:mm a')}`}
      </p>
    </div>
  )
}

AuctionHistoryItem.defaultProps = {
  className: '',
  price: {},
}

AuctionHistoryItem.propTypes = {
  className: PropTypes.string,
  timestamp: PropTypes.number.isRequired,
  price: PropTypes.shape({
    amount: PropTypes.any,
    currencyName: PropTypes.string,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    profileUrl: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf(Object.values(AuctionHistoryItemType)).isRequired,
}

export default AuctionHistoryItem
