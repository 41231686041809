import React, { useState } from 'react'
import classNames from 'classnames'
import { Nav } from 'react-bootstrap'

import * as style from './BlockCommunityVotes.module.scss'
import { MOCK_COMMUNITY_UPVOTE_ITEMS } from '~utils/mocks'
import ItemCommunityUpvote from '../ItemCommunityUpvote'

/**
 * BlockCommunityVotes component
 */
const BlockMain = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isVerified } = props

  const defaultNavKey = 'top50'
  const [activeNavKey, setActiveNavKey] = useState(defaultNavKey)

  const handleNavSelection = (e) => setActiveNavKey(e)

  return (
    <>
      {isVerified && (
        <Nav
          variant="white"
          defaultActiveKey={defaultNavKey}
          className={style.nav}
          onSelect={handleNavSelection}
        >
          <Nav.Item>
            <Nav.Link eventKey="top50">Top 50</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="yourVotes">Your votes</Nav.Link>
          </Nav.Item>
        </Nav>
      )}
      {isVerified && (
        <p className={style.textVotesAvailable}>
          You have <span className={style.textVotesNumber}>4</span> votes
          available.
        </p>
      )}
      <div
        className={classNames(style.blockCommunityUpvotes, {
          [style.isUnverified]: !isVerified,
        })}
      >
        {MOCK_COMMUNITY_UPVOTE_ITEMS.filter(
          (auction) => activeNavKey === 'top50' || auction.hasReceivedVote
        ).map((item) => (
          <ItemCommunityUpvote key={item.place} {...item} />
        ))}
      </div>
    </>
  )
}

export default BlockMain
