import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './FilterWithImageItem.module.scss'
import Icon from '~components/ui/Icon'

/**
 * FilterWithImageItem component
 */
const FilterWithImageItem = (props) => {
  const {
    className,
    name,
    img,
    itemId,
    activeItemId,
    onSelect,
    onClear,
    isActive,
    ...rest
  } = props

  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        style.filterWithImageItem,
        { [style.active]: isActive, [style.noImage]: !img },
        className
      )}
      onClick={isActive ? onClear : onSelect}
    >
      {img && (
        <img
          src={img}
          alt="name"
          width={32}
          height={32}
          className={style.img}
        />
      )}
      <div className={style.textName}>{name}</div>
      <div
        className={classNames(style.iconClearWrapper, {
          [style.isVisible]: isActive,
        })}
      >
        <Icon name="icon-filter-close" size={16} className={style.iconClear} />
      </div>
    </button>
  )
}

FilterWithImageItem.defaultProps = {
  className: '',
  onClear: () => {},
  onSelect: () => {},
}

FilterWithImageItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  itemId: PropTypes.any.isRequired,
  activeItemId: PropTypes.any.isRequired,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
}

export default FilterWithImageItem
