import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useScrollSegmentToAlpha from '~hooks/useScrollSegmentToAlpha'

/**
 * Blend Fade Shadow component
 */
const BlendFadeShadow = (props) => {
  const { className, scrollContainerRef, fadeStart, fadeEnd, ...rest } = props

  const { handleScroll, alpha: blendOverlayOpacity } = useScrollSegmentToAlpha(
    fadeEnd,
    fadeStart
  )

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef.current

    if (!scrollContainerEl) return

    const scrollEventListener = scrollContainerEl.addEventListener(
      'scroll',
      handleScroll,
      {
        passive: true,
      }
    )

    // eslint-disable-next-line consistent-return
    return () =>
      scrollContainerEl.removeEventListener('scroll', scrollEventListener)
  }, [scrollContainerRef.current])

  return (
    <div
      {...rest}
      className={className}
      style={{ opacity: blendOverlayOpacity }}
    />
  )
}

BlendFadeShadow.defaultProps = {
  className: '',
}

BlendFadeShadow.propTypes = {
  className: PropTypes.string,
  fadeStart: PropTypes.number.isRequired,
  fadeEnd: PropTypes.number.isRequired,
  scrollContainerRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
}

export default BlendFadeShadow
