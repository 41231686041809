// extracted by mini-css-extract-plugin
export var itemTransaction = "bf_fF";
export var iconContainer = "bf_fG";
export var colType = "bf_fH";
export var textDesc = "bf_fJ";
export var textDescLink = "bf_fK";
export var textPrimary = "bf_fL";
export var textId = "bf_fM";
export var statusIndicator = "bf_fN";
export var textDateTime = "bf_fP";
export var textTime = "bf_fQ";
export var blockDateTimeXxl = "bf_fR";
export var colAmount = "bf_fS";
export var textAmountMain = "bf_fT";
export var textAmountExtra = "bf_fV";
export var btnExpand = "bf_fW";
export var iconChevron = "bf_fX";
export var active = "bf_fY";
export var accordionCollapse = "bf_fZ";
export var blockExpand = "bf_f0";
export var blockExpandRow = "bf_f1";
export var rotate = "bf_c";
export var dotty = "bf_d";