// extracted by mini-css-extract-plugin
export var cardPersonal = "K_dd";
export var banner = "K_df";
export var btnMoreActions = "K_dg";
export var cardBody = "K_dh";
export var avatar = "K_5";
export var gridName = "K_dj";
export var textNickname = "K_dk";
export var gridStats = "K_dl";
export var btnStats = "K_dm";
export var gridButtons = "K_dn";
export var variantProfilePage = "K_dp";
export var inputCopy = "K_dq";
export var rotate = "K_c";
export var dotty = "K_d";