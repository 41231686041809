import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormText } from 'react-bootstrap'

import * as style from './InputPrice.module.scss'

/**
 * InputPrice component
 */
const InputPrice = (props) => {
  const { className, errors, name, ...rest } = props

  return (
    <div className={className}>
      <div className={style.inputPrice}>
        <FormControl {...rest} name={name} className={style.formControl} />
        <p className={style.currency}>TRAIL</p>
      </div>
      {!!errors?.[name] && (
        <FormText className="form-text--error">{errors[name].message}</FormText>
      )}
    </div>
  )
}

InputPrice.defaultProps = {
  className: '',
  errors: {},
}

InputPrice.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
}

export default InputPrice
