import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useResizeDetector } from 'react-resize-detector'

import * as style from './ConnectedWallet.module.scss'
import { insertMiddleEllipsis } from '~utils'

/**
 * ConnectedWallet component
 */
const ConnectedWallet = (props) => {
  const { className, ...rest } = props

  const value = '0x9486371gdwert45erthetr0bC618148sC238'

  const { width, ref: textRef } = useResizeDetector({ handleHeight: false })
  const integerWidth = Math.floor(width)

  const textWMiddleEllipsis = useMemo(
    () => insertMiddleEllipsis(value, integerWidth),
    [value, integerWidth]
  )

  return (
    <div {...rest} className={className}>
      <p className={style.textLabel}>Connected to</p>
      <div className={style.connectedWallet}>
        <p className={style.textWalletAddr} ref={textRef}>
          {textWMiddleEllipsis}
        </p>
      </div>
    </div>
  )
}

ConnectedWallet.defaultProps = {
  className: '',
}

ConnectedWallet.propTypes = {
  className: PropTypes.string,
}

export default ConnectedWallet
