import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal } from 'react-bootstrap'
import classNames from 'classnames'
import { useForm, FormProvider } from 'react-hook-form'
import { upperFirst } from 'lodash'
import { DevTool } from '@hookform/devtools'

import * as style from './ModalEditProfile.module.scss'
import InputCopy from '~components/ui/InputCopy'
import InputWLabel from '~components/ui/InputWLabel'
import InputImage from '~components/ui/InputImage'
import {
  SOCIAL_MEDIA_INPUTS,
  RHF_URL_RULE,
  RHF_TWITTER_RULE,
} from '~utils/constants'

/**
 * ModalEditProfile component
 */
const ModalEditProfile = (props) => {
  const { show, onHide, className, ...rest } = props

  const methods = useForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods

  const onSubmit = () => {
    onHide()
  }

  // Mock data
  // twitter.com/johnsmith
  const verifiedTwitterUrl = ''

  return (
    <Modal
      {...rest}
      show={show}
      onHide={onHide}
      backdrop="static"
      scrollable
      contentClassName={classNames(style.modalEditProfile, className)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit profile</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Modal.Body
          className={style.modalBody}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          id="form-personal-info"
        >
          <div className={style.blockInner}>
            <h5>Personal information</h5>
            <div className={style.gridInputs}>
              <InputWLabel
                id="inp-name"
                label="Name"
                defaultValue="John Smith"
                {...register('name', {
                  required: 'Please, specify your full name',
                  pattern: {
                    value: /^[A-Za-z][A-Za-z.\-\s]{0,30}$/,
                    message:
                      'The name may only contain latin letters, "-" and "." characters and spaces. The name should only start with a letter',
                  },
                })}
                errors={errors}
              />
              <InputWLabel
                id="inp-username"
                label="Username"
                defaultValue="johnsmith"
                variant="username"
                {...register('username', {
                  required: 'Please, specify your username',
                  pattern: {
                    value: /^[A-Za-z]\w{5,29}$/,
                    message:
                      'The username should be from 6 to 30 characters long, start with a latin letter and contain only latin letters, numbers and underscores',
                  },
                })}
                errors={errors}
              />
              <InputWLabel
                id="inp-email"
                label="Email for notifications"
                defaultValue="johnsmith@example.com"
                type="email"
                {...register('email', {
                  required: 'Please, specify your email',
                  pattern: {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: 'Please, enter a valid email address',
                  },
                })}
                errors={errors}
              />
              <InputImage
                label="Profile avatar"
                id="inp-img-avatar"
                name="imgAvatar"
                text="Drag & drop an image here, or click to browse"
                hint="512x512px. JPG, PNG or GIF. 5MB max size."
                dropzoneOptions={{
                  accept: 'image/jpeg, image/png, image/gif',
                  maxSize: 5 * 1000 * 1000, // 5MB
                  maxFiles: 1,
                  multiple: false,
                }}
                msgFileType="Please, submit a jpg, png or gif file"
                errors={errors}
              />
              <div>
                <InputImage
                  label="Cover image"
                  id="inp-img-cover"
                  name="imgCover"
                  text="Drag & drop an image here, or click to browse"
                  hint="512x512px. JPG, PNG or GIF. 5MB max size."
                  imgData={{
                    id: 1234,
                    url: `https://picsum.photos/300/200?nocache=${Math.random()}`,
                    name: 'background.png',
                    size: 1.2 * 1000 * 1000,
                  }}
                  dropzoneOptions={{
                    accept: 'image/jpeg, image/png, image/gif',
                    maxSize: 5 * 1000 * 1000, // 5MB
                    maxFiles: 1,
                    multiple: false,
                  }}
                  msgFileType="Please, submit a jpg, png or gif file"
                  errors={errors}
                />
              </div>
            </div>
            <hr />
            <h5>Socials</h5>
            <div className={style.gridInputs}>
              <InputWLabel
                id="inp-website"
                label="Website"
                type="url"
                defaultValue="johnsmith.com"
                {...register('website', RHF_URL_RULE)}
                errors={errors}
              />
              {SOCIAL_MEDIA_INPUTS.map(({ name, label, placeholder }) => (
                <InputWLabel
                  key={name}
                  id={`inp-${name}`}
                  label={label ?? upperFirst(name)}
                  type="url"
                  placeholder={placeholder ?? `${name}.com/`}
                  {...register(name, RHF_URL_RULE)}
                  errors={errors}
                />
              ))}
            </div>
            <hr />
            <h5>Verification</h5>
            <div className={style.gridInputs}>
              {!verifiedTwitterUrl ? (
                <>
                  <div>
                    <Form.Label>
                      Post a public tweet that contains your wallet address
                    </Form.Label>
                    <InputCopy value="0x9486371gd44js56d4k692dfk30dl0bC618148sC238" />
                  </div>
                  <InputWLabel
                    id="inp-tweet-url"
                    label="Paste the URL of the tweet to verify your profile."
                    placeholder="Tweet URL"
                    type="url"
                    hint="We will review your tweet and verify it within 5 working days."
                    {...register('tweetUrl', RHF_TWITTER_RULE)}
                    errors={errors}
                  />
                </>
              ) : (
                <div>
                  <Form.Label>Your account has been verified</Form.Label>
                  <InputCopy value={verifiedTwitterUrl} textAlign="start" />
                  <Form.Text>
                    Please, contact support if you will lose access to your
                    account.
                  </Form.Text>
                </div>
              )}
            </div>
            <hr />
            <h5>Connect Traveling account</h5>
            <InputWLabel
              id="inp-trail-account"
              label="Trail account"
              placeholder="trail.com/"
              type="url"
              {...register('trailAccount', RHF_URL_RULE)}
              errors={errors}
            />
          </div>
        </Modal.Body>
      </FormProvider>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="form-personal-info">
          Save
        </Button>
      </Modal.Footer>
      <DevTool control={control} />
    </Modal>
  )
}

ModalEditProfile.defaultProps = {
  className: '',
}

ModalEditProfile.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default ModalEditProfile
