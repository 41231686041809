import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import { DevTool } from '@hookform/devtools'

import * as style from './mintingBlocks.module.scss'
import InputPrice from '../InputPrice'
import { maskNumberValue } from '~utils'

/**
 * BlockListing component
 */
const BlockListing = (props) => {
  const { className, onSubmit, ...rest } = props

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  return (
    <form
      {...rest}
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.columnInfo, className)}
    >
      <h2 className={classNames(style.textHeading, style.marginLg)}>
        Set a reserve price
      </h2>
      <Controller
        name="price"
        control={control}
        rules={{ required: 'Please, specify the price' }}
        render={({ field: { onChange, ...fieldProps } }) => (
          <InputPrice
            {...fieldProps}
            id="inp-price"
            placeholder="Price"
            className={style.inputPrice}
            errors={errors}
            onChange={(e) => onChange(maskNumberValue(e.target.value))}
          />
        )}
      />
      <p>
        This price will be made public. Bidders will not be able to bid below
        this price. Once a bid has been placed, a 24 hour auction for the price
        will begin.
      </p>
      <Button variant="primary" type="submit" className={style.btnList}>
        List your NFT
      </Button>
      <DevTool control={control} />
    </form>
  )
}

BlockListing.defaultProps = {
  className: '',
  onSubmit: () => {},
}

BlockListing.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default BlockListing
