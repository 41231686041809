// extracted by mini-css-extract-plugin
export var filterWithImageItem = "by_g2";
export var noImage = "by_g3";
export var active = "by_fY";
export var img = "by_cz";
export var textName = "by_gq";
export var iconClearWrapper = "by_g4";
export var isVisible = "by_g5";
export var iconClear = "by_gC";
export var rotate = "by_c";
export var dotty = "by_d";