// extracted by mini-css-extract-plugin
export var gridHeading = "g_p";
export var isUnverified = "g_q";
export var textComment = "g_r";
export var btnJoin = "g_s";
export var blockHeading = "g_t";
export var isVerified = "g_v";
export var cardPosition = "g_w";
export var textSup = "g_x";
export var textPos = "g_y";
export var textSub = "g_z";
export var rotate = "g_c";
export var dotty = "g_d";