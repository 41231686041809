import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './CreateOption.module.scss'
import Icon from '~components/ui/Icon'

/**
 * CreateOption component
 */
const CreateOption = (props) => {
  const { className, heading, format, icon, ...rest } = props

  return (
    <button
      {...rest}
      className={classNames(style.createOption, className)}
      type="button"
    >
      <div className={style.iconWrapper}>
        <div className={style.iconAspectRatioContainer}>
          <Icon
            name={`icon-create-nft-${icon}`}
            size={82}
            className={style.icon}
          />
        </div>
      </div>
      <div className={style.blockText}>
        <h5>{heading}</h5>
        <p className={style.textFormat}>{format}</p>
      </div>
    </button>
  )
}

CreateOption.defaultProps = {
  className: '',
}

CreateOption.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default CreateOption
