/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import S from '~components/seo'
import Layout from '~components/general/Layout'
import * as style from './WalletPage.module.scss'
import CardTransactions from './components/CardTransactions'
import CardInformative from './components/CardInformative'
import CardWalletActions from './components/CardWalletActions'
import Route from '~routes'
import CarouselWallet from './components/CarouselWallet'
import { MOCK_WALLETS } from '~utils/mocks'

gsap.registerPlugin(ScrollToPlugin)
/**
 * WalletPage component
 */
const WalletPage = (props) => {
  const { data } = props
  const { site } = data

  const [activeWallet, setActiveWallet] = useState(MOCK_WALLETS[0])

  return (
    <Layout
      siteTitle={site.siteMetadata.title}
      isLoggedIn
      activePage={Route.WALLET}
    >
      <Container>
        <S title="Wallet" />
        <CarouselWallet
          activeWallet={activeWallet}
          setActiveWallet={setActiveWallet}
          headingText="Your Wallets"
        />
        <div className={style.gridMain}>
          <CardTransactions className={style.cardTransactions} />
          <CardWalletActions className={style.cardWalletActions} />
          <CardInformative
            heading="Be attentive"
            text="It can take from five days up to a month to process the transaction."
            className={style.cardInfo}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default WalletPage
