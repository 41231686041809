import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'gatsby'
import * as style from './AuctionRelatedEntity.module.scss'

/**
 * AuctionRelatedEntity component
 */
const AuctionRelatedEntity = (props) => {
  const { className, img, name, description, profileUrl, ...rest } = props

  return (
    <div {...rest} className={classNames(style.auctionRelatedUser, className)}>
      <Link to={profileUrl} className={style.imgWrapper}>
        <img src={img} alt={name} className={style.img} />
      </Link>
      <p className={style.textDesc}>{description}</p>
      <Link to={profileUrl} className={style.textName}>
        {name}
      </Link>
    </div>
  )
}

AuctionRelatedEntity.defaultProps = {
  className: '',
}

AuctionRelatedEntity.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
}

export default AuctionRelatedEntity
