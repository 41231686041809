import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import cn from 'classnames'

import * as style from './TopSellerSection.module.scss'
import TopSellerItem from '../TopSellerItem'
import BlendFadeShadow from '~components/ui/BlendFadeShadow'
import DropdownFilterContext from '~contexts/DropdownFilterContext'
import DropdownFilter from '~components/ui/DropdownFilter'
import {
  ExploreDateRange,
  ExploreUserType,
} from '~containers/Homepage/constants'

// eslint-disable-next-line react/prop-types
const TopSellerExample = ({ rating }) => (
  <TopSellerItem
    rating={rating}
    name="@katyperry"
    authorImgSrc={`https://picsum.photos/96/96?nocache=${Math.random()}`}
    amount="42,540"
    currency="TRAIL"
  />
)
/**
 * TopSellerDeck component
 */
const TopSellerSection = (props) => {
  const { className, ...rest } = props

  const scrollContainerRef = useRef()

  const [filters, setFilters] = useState({
    userType: ExploreUserType.sellers,
    dateRange: ExploreDateRange.day,
  })

  return (
    <>
      <div className={style.sectionHeading}>
        <DropdownFilterContext.Provider value={{ filters, setFilters }}>
          <h3>
            Top{' '}
            <DropdownFilter
              filterEnum={ExploreUserType}
              filterKey="userType"
              isClearable={false}
              variant="inlinePrimary"
            />
            in{' '}
            <DropdownFilter
              filterEnum={ExploreDateRange}
              filterKey="dateRange"
              isClearable={false}
              variant="inlinePrimary"
            />
          </h3>
        </DropdownFilterContext.Provider>
        <Button
          variant="outline-secondary"
          className={style.btnViewAllTopSellers}
        >
          View All
        </Button>
      </div>
      {/* Mobile * <- 640px */}
      <div
        className={cn(style.topSellerSection, style.sm, className)}
        {...rest}
      >
        <BlendFadeShadow
          scrollContainerRef={scrollContainerRef}
          fadeStart={0.8}
          fadeEnd={0.92}
          className={style.blendOverlay}
        />
        <div className={style.scrollContainer} ref={scrollContainerRef}>
          <div className={cn(style.topSellerDeck, style.sm)}>
            <TopSellerExample rating={1} />
            <TopSellerExample rating={2} />
            <TopSellerExample rating={3} />
            <TopSellerExample rating={4} />
            <TopSellerExample rating={5} />
            <TopSellerExample rating={6} />
            <TopSellerExample rating={7} />
            <TopSellerExample rating={8} />
            <TopSellerExample rating={9} />
            <TopSellerExample rating={10} />
            <TopSellerExample rating={11} />
            <TopSellerExample rating={12} />
          </div>
        </div>
      </div>
      {/* Mobile, Desktop 640px -> */}
      <div
        className={cn(style.topSellerSection, style.mdLg, className)}
        {...rest}
      >
        {/* MdLg 640px -> * */}
        <div className={cn(style.topSellerDeck, style.mdLg)}>
          <TopSellerExample rating={1} />
          <TopSellerExample rating={2} />
          <TopSellerExample rating={3} />
        </div>
        {/* Lg 1024px -> * */}
        <div className={cn(style.topSellerDeck, style.lg)}>
          <TopSellerExample rating={4} />
          <TopSellerExample rating={5} />
          <TopSellerExample rating={6} />
          <TopSellerExample rating={7} />
          <TopSellerExample rating={8} />
          <TopSellerExample rating={9} />
          <TopSellerExample rating={10} />
          <TopSellerExample rating={11} />
          <TopSellerExample rating={12} />
        </div>
        {/* Md 640px -> 1024px */}
        <div className={cn(style.topSellerDeck, style.md)}>
          <TopSellerExample rating={4} />
          <TopSellerExample rating={5} />
          <TopSellerExample rating={6} />
        </div>
        {/* Md 640px -> 1024px */}
        <div className={cn(style.topSellerDeck, style.md)}>
          <TopSellerExample rating={7} />
          <TopSellerExample rating={8} />
          <TopSellerExample rating={9} />
        </div>
        {/* Md 640px -> 1024px */}
        <div className={cn(style.topSellerDeck, style.md)}>
          <TopSellerExample rating={10} />
          <TopSellerExample rating={11} />
          <TopSellerExample rating={12} />
        </div>
      </div>
    </>
  )
}

TopSellerSection.defaultProps = {
  className: '',
}

TopSellerSection.propTypes = {
  className: PropTypes.string,
}

export default TopSellerSection
