const COMMUNITY_UPVOTE_HEADING = {
  verified: 'You’re in the Community Upvote!',
  unVerified: 'Community Upvote!',
}

const COMMUNITY_UPVOTE_PARAGRAPH = {
  verified: 'You’re in the Community Upvote!',
  unVerified: 'Community Upvote!',
}

export { COMMUNITY_UPVOTE_PARAGRAPH, COMMUNITY_UPVOTE_HEADING }
