// extracted by mini-css-extract-plugin
export var rowPageHeading = "B_bX";
export var blockWallets = "B_bY";
export var gridWallets = "B_bZ";
export var gridWalletsInner = "B_b0";
export var blendShadowLeft = "B_b1";
export var blendShadowRight = "B_b2";
export var hidden = "B_b3";
export var gridNavButtons = "B_b4";
export var rotate = "B_c";
export var dotty = "B_d";