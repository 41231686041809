import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, FormControl, FormLabel, FormText } from 'react-bootstrap'

import * as style from './InputBidding.module.scss'

/**
 * InputBidding component
 */
const InputBidding = React.forwardRef((props, ref) => {
  const {
    className,
    id,
    name,
    label,
    onMinBidClick,
    isMinBid,
    valueConverted,
    errors,
    ...rest
  } = props

  return (
    <div className={classNames(style.inputBidding, className)}>
      {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <div className={style.inputWrapper}>
        <FormControl {...rest} id={id} ref={ref} name={name} />
        <Button
          variant="outline-primary"
          type="button"
          className={style.btnMinBid}
          onClick={onMinBidClick}
          disabled={isMinBid}
        >
          MIN BID
        </Button>
      </div>
      {!!errors?.[name] && (
        <FormText className="form-text--error">{errors[name].message}</FormText>
      )}
      {!!valueConverted && (
        <p className={style.textValueConverted}>{valueConverted}</p>
      )}
    </div>
  )
})

InputBidding.defaultProps = {
  className: '',
  label: '',
  onMinBidClick: () => {},
  isMinBid: false,
  valueConverted: null,
  errors: {},
}

InputBidding.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onMinBidClick: PropTypes.func,
  isMinBid: PropTypes.bool,
  valueConverted: PropTypes.any,
  errors: PropTypes.object,
}

export default InputBidding
