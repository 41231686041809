import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AccordionContext, useAccordionButton } from 'react-bootstrap'

import * as style from './BtnWIconToggle.module.scss'
import Icon from '~components/ui/Icon'

/**
 * BtnWIconToggle component
 */
const BtnWIconToggle = React.forwardRef((props, ref) => {
  const { className, eventKey, callback, setIsFilterBarExtended, ...rest } =
    props

  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = activeEventKey === eventKey

  setIsFilterBarExtended(isCurrentEventKey)

  return (
    <button
      {...rest}
      className={classNames(
        style.btnWIconToggle,
        { [style.active]: isCurrentEventKey },
        className
      )}
      type="button"
      ref={ref}
      onClick={decoratedOnClick}
    >
      <span>Filter</span>
      <Icon name="icon-btn-transaction-filter" size={20} />
    </button>
  )
})

BtnWIconToggle.defaultProps = {
  className: '',
  eventKey: null,
  callback: () => {},
  setIsFilterBarExtended: () => {},
}

BtnWIconToggle.propTypes = {
  className: PropTypes.string,
  eventKey: PropTypes.any,
  callback: PropTypes.func,
  setIsFilterBarExtended: PropTypes.func,
}

export default BtnWIconToggle
