import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import * as style from './BidCountdown.module.scss'

dayjs.extend(duration)

/**
 * BidCountdown component
 */
const BidCountdown = (props) => {
  const {
    className,
    endsAtTimestamp,
    variant,
    price,
    priceUsd,
    currencyName,
    ...rest
  } = props

  const [countdownDiff, setCountdownDiff] = useState()

  const calcCountdownDiff = (endsDay, interval) => {
    const nowDay = dayjs()

    const diff = endsDay.diff(nowDay)
    const diffDuration = dayjs.duration(diff)
    setCountdownDiff(diffDuration)

    if (interval && diffDuration.asSeconds() < 1) clearInterval(interval)
  }

  useEffect(() => {
    if (!endsAtTimestamp) return

    const endsDay = dayjs.unix(endsAtTimestamp)
    calcCountdownDiff(endsDay)

    const interval = setInterval(() => {
      calcCountdownDiff(endsDay, interval)
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval)
  }, [endsAtTimestamp])

  return (
    <div
      {...rest}
      className={classNames(
        style.bidCountdown,
        { [style[variant]]: variant },
        className
      )}
    >
      <div>
        <p className={style.infoColumnTitle}>Auction Ends in</p>
        <div className={style.countdownGrid}>
          <h3>{countdownDiff?.format('H') ?? '00'}</h3>
          <p>Hours</p>
          <h3>{countdownDiff?.format('m') ?? '00'}</h3>
          <p>Mins</p>
          <h3>{countdownDiff?.format('s') ?? '00'}</h3>
          <p>Secs</p>
        </div>
      </div>
      <div className={style.columnBid}>
        <div>
          <p className={style.infoColumnTitle}>Current Bid</p>
          <div className={style.currentBidGrid}>
            <h3 className={style.textPrice}>
              {price} <span className={style.currencyName}>{currencyName}</span>
            </h3>
            <p>~ ${priceUsd}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

BidCountdown.defaultProps = {
  className: '',
  variant: null,
}

BidCountdown.propTypes = {
  className: PropTypes.string,
  endsAtTimestamp: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  priceUsd: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default BidCountdown
